import React, { createContext, useState, useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import { merge } from 'lodash';
import { IntlProvider } from 'react-intl';
import { getLocaleData } from 'src/i18n/helpers';

interface Settings {
  language?: 'en' | 'de';
}

export interface SettingsContextValue {
  settings: Settings;
  saveSettings: (update: Settings) => void;
}

interface SettingsProviderProps {
  settings?: Settings;
  children?: ReactNode;
}

const defaultSettings: Settings = {
  language: 'de'
};

export const restoreSettings = (): Settings | null => {
  let settings = null;

  try {
    const storedData: string | null = window.localStorage.getItem('settings');

    if (storedData) {
      settings = JSON.parse(storedData);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }

  return settings;
};

export const storeSettings = (settings: Settings): void => {
  window.localStorage.setItem('settings', JSON.stringify(settings));
};

const SettingsContext = createContext<SettingsContextValue>({
  settings: defaultSettings,
  saveSettings: () => {}
});

export const SettingsProvider: FC<SettingsProviderProps> = ({
  settings,
  children
}) => {
  const [currentSettings, setCurrentSettings] = useState<Settings>(
    settings || defaultSettings
  );

  const handleSaveSettings = (update: Settings = {}): void => {
    const mergedSettings = merge({}, currentSettings, update);

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  useEffect(() => {
    const restoredSettings = restoreSettings();

    if (restoredSettings) {
      setCurrentSettings(restoredSettings);
    }
  }, []);

  const intlProps = getLocaleData(currentSettings.language);

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings
      }}
    >
      <IntlProvider {...intlProps}>{children}</IntlProvider>
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
