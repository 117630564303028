export interface Config {
  env: 'stage' | 'develop' | 'uat' | 'production' | 'local';
  enableReduxDevTools: boolean;
  [key: string]: unknown;

  locale: string;
  timezone: string;
  apiBaseUrl: string;
  default_partner_account_id?: number;
}

const local: Config = {
  env: 'local',
  enableReduxDevTools: true,
  apiBaseUrl: 'http://api-service.roobeo.localhost',
  locale: 'de-DE',
  timezone: 'Europe/Berlin'
};

const develop: Config = {
  env: 'develop',
  enableReduxDevTools: true,
  apiBaseUrl: 'http://api-service.roobeo.mv.remote.roobeo.io',
  locale: 'de-DE',
  timezone: 'Europe/Berlin'

};

const stage: Config = {
  env: 'stage',
  enableReduxDevTools: false,
  apiBaseUrl: 'https://orchestration-service.stage.roobeo.com',
  locale: 'de-DE',
  timezone: 'Europe/Berlin'
};

const uat: Config = {
  env: 'uat',
  enableReduxDevTools: false,
  apiBaseUrl: 'https://orchestration-service.roobeo.com',
  locale: 'de-DE',
  timezone: 'Europe/Berlin'
};

const production: Config = {
  env: 'production',
  enableReduxDevTools: false,
  apiBaseUrl: 'https://orchestration-service.roobeo.com',
  locale: 'de-DE',
  timezone: 'Europe/Berlin'
};

let envConfig: Config;
switch (process.env.REACT_APP_ENV) {
  case 'local':
    envConfig = local;
    break;
  case 'develop':
    envConfig = develop;
    break;
  case 'stage':
    envConfig = stage;
    break;
  case 'uat':
    envConfig = production;
    break;
  case 'production':
    envConfig = production;
    break;
  default:
    envConfig = develop;
}
const config = envConfig;
config.default_partner_account_id = 467;

export default config;
