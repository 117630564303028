import React, {
  Suspense, Fragment, lazy, FC
} from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import Can from 'src/components/Can';
import type { Permissions } from 'src/types/permission';
import { lazyRetry } from './utils/lazy-retry';
import useAuth from './hooks/useAuth';
// TODO: when same permission used for two routes but one should see another one : TBD => check
// craeteRoleView

type RoutesParams = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: RoutesParams;
  permission?: string[];
}[];

export const renderRoutes = (
  routes: RoutesParams = [],
  permissions: Permissions
): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        const permission = route.permission || [];
        const id = i + (Math.floor(Math.random() * 10));
        return (
          <Route
            key={id}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes, permissions)
                  ) : permission.length ? (
                    <Can
                      userPermissions={permissions}
                      perform={permission}
                      yes={() => <Component {...props} />}
                      no={() => <Redirect to="/" />}
                    />
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: RoutesParams = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => lazyRetry(() => import('src/views/errors/NotFoundView'), '404'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => lazyRetry(() => import('src/views/auth/LoginView'), 'login-view'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/home',
        component: lazy(() => lazyRetry(() => import('src/views/home'), 'home-view'))
      },
      {
        exact: true,
        path: '/app/reporting/:reportName',
        component: lazy(() => lazyRetry(() => import('src/views/tableau/TableauOverview'), 'tableau-overview-view')),
        permission: ['INS_CMR', 'INS_SUP', 'INS_PNR', 'INS_INT', 'CAN_ACCELERATE']
      },
      {
        exact: true,
        path: '/app/reporting/:reportName/:subReportName',
        component: lazy(() => lazyRetry(() => import('src/views/tableau/TableauOverview'), 'tableau-overview-view')),
        permission: ['INS_CMR', 'INS_SUP', 'INS_PNR', 'INS_INT', 'CAN_ACCELERATE']
      },
      {
        exact: true,
        path: '/app/reporting/internal',
        component: lazy(() => lazyRetry(() => import('src/views/tableau/TableauOverview'), 'tableau-overview-view')),
        permission: ['INS_CMR', 'INS_SUP', 'INS_PNR', 'INS_INT', 'CAN_ACCELERATE']
      },
      {
        exact: true,
        path: '/app/management/partners',
        component: lazy(() => lazyRetry(() => import('src/views/partner/PartnerListView'), 'partner-list-view')),
        permission: ['CRUD_PARTNER_ACCOUNT']
      },
      {
        exact: true,
        path: '/app/management/partners/:partnerId',
        component: lazy(() => lazyRetry(() => import('src/views/partner/PartnerDetailView'), 'partner-detail-view')),
        permission: ['CRUD_PARTNER_ACCOUNT']
      },
      {
        exact: true,
        path: '/app/management/partners/:partnerId/create-role/',
        component: lazy(() => lazyRetry(() => import('src/views/partner/UserCreateRole'), 'partner-user-create-role')),
        permission: [
          'CRUD_USER_ROLE',
          'CRUD_USER_ROLE_ROOBEO',
          'CRUD_PARTNER_ACCOUNT'
        ]
      },
      {
        exact: true,
        path: '/app/management/users/create-role/',
        component: lazy(() => lazyRetry(() => import('src/views/user/UserCreateRole'), 'user-create-role')),
        permission: ['CRUD_USER_ROLE', 'CRUD_USER_ROLE_ROOBEO']
      },
      {
        exact: true,
        path: '/app/management/users/',
        component: lazy(() => lazyRetry(() => import('src/views/user/UserListView'), 'user-list-view')),
        permission: ['R_USER_MANAGEMENT']
      },
      {
        exact: true,
        path: '/app/management/users/new',
        component: lazy(() => lazyRetry(() => import('src/views/user/UserCreateView'), 'user-create-view')),
        permission: ['CRUD_USER_ACCOUNT']
      },
      {
        exact: true,
        path: '/app/management/user/:userId/edit',
        component: lazy(() => lazyRetry(() => import('src/views/user/UserEditView'), 'user-edit-view')),
        permission: ['CRUD_USER_ACCOUNT', 'CRUD_PARTNER_ACCOUNT']
      },
      {
        exact: true,
        path: '/app/management/partner/:partnerId/user/:userId/edit',
        component: lazy(() => lazyRetry(() => import('src/views/partner/UserEditView'), 'partner-user-edit-view')),
        permission: ['CRUD_PARTNER_ACCOUNT']
      },
      {
        exact: true,
        path: '/app/management/partner/:partnerId/user-role/:userRoleId/edit',
        component: lazy(() => lazyRetry(() => import('src/views/partner/UserEditRole'), 'partner-user-role-edit-view')),
        permission: ['CRUD_USER_ROLE', 'CRUD_USER_ROLE_ROOBEO']
      },
      {
        exact: true,
        path: '/app/management/user-role/:userRoleId/edit',
        component: lazy(() => lazyRetry(() => import('src/views/user/UserEditRole'), 'user-role-edit-view')),
        permission: ['CRUD_USER_ROLE', 'CRUD_USER_ROLE_ROOBEO']
      },
      {
        exact: true,
        path: '/app/management/partners/:partnerId/edit',
        component: lazy(() => lazyRetry(() => import('src/views/partner/PartnerEditView'), 'partner-edit-view')),
        permission: ['CRUD_PARTNER_ACCOUNT']
      },
      {
        exact: true,
        path: '/app/management/partners/:partnerId/users/new',
        component: lazy(() => lazyRetry(() => import('src/views/partner/UserCreateView'), 'partner-user-create-view')),
        permission: ['CRUD_PARTNER_ACCOUNT']
      },
      {
        exact: true,
        path: '/app/management/assortment/roobeo-assortments',
        component: lazy(
          () => lazyRetry(() => import('src/views/trade-items/TradeItemsListView'), 'trade-items-list-view')
        ),
        permission: ['R_TRADE_ITEM_ROOBEO', 'R_TRADE_ITEM_SUPPLIER']
      },
      {
        exact: true,
        path: '/app/management/assortment/my-assortments',
        component: lazy(
          () => lazyRetry(() => import('src/views/trade-items/TradeItemsListView/MyTradeItems'), 'my-trade-items')
        ),
        permission: [
          'R_TRADE_ITEM_ROOBEO',
          'R_TRADE_ITEM_SUPPLIER',
          'R_TRADE_ITEM_CUSTOMER'
        ]
      },
      {
        exact: true,
        path: '/app/management/assortment/trade-items/:tradeItemId',
        component: lazy(
          () => lazyRetry(() => import('src/views/trade-items/TradeItemDetailView'), 'trade-item-detail-view')
        ),
        permission: [
          'R_TRADE_ITEM_ROOBEO',
          'R_TRADE_ITEM_SUPPLIER',
          'R_TRADE_ITEM_CUSTOMER'
        ]
      },
      {
        exact: true,
        path: '/app/management/assortment/trade-items/:tradeItemId/new-tio',
        component: lazy(() => lazyRetry(() => import('src/views/trade-item-offer/CreateTIOView'), 'trade-item-offer-create')),
        permission: [
          'CUD_TRADE_ITEM_OFFER_SUPPLIER',
          'CUD_TRADE_ITEM_OFFER_ROOBEO'

        ]
      },
      {
        exact: true,
        path: '/app/management/assortment/trade-item-offer/:tradeItemOfferId/edit',
        component: lazy(() => lazyRetry(() => import('src/views/trade-item-offer/EditTIOView'), 'trade-item-offer-edit')),
        permission: [
          'CUD_TRADE_ITEM_OFFER_SUPPLIER',
          'CUD_TRADE_ITEM_OFFER_ROOBEO'
        ]
      },
      {
        exact: true,
        path: '/app/management/orders/clarification-cases/:clarificationUuid',
        component: lazy(() => lazyRetry(() => import('src/views/clarification-case/CaseDetailView'), 'case-detail-view')),
        permission: [
          'R_CLARIFICATION_MANAGEMENT'
        ]
      },
      {
        exact: true,
        path: '/app/management/orders/clarification-cases/',
        component: lazy(() => lazyRetry(() => import('src/views/clarification-case/CasesListView'), 'case-list-view')),
        permission: [
          'R_CLARIFICATION_MANAGEMENT'
        ]
      },
      {
        exact: true,
        path: '/app/management/orders/overview/',
        component: lazy(() => lazyRetry(() => import('src/views/orders/OrdersOverview'), 'order-overview')),
        permission: [
          'R_ORDER_OVERVIEW_ROOBEO'
        ]
      },
      {
        exact: true,
        path: '/app/management/orders/:outboundOrderId',
        component: lazy(() => lazyRetry(() => import('src/views/orders/OrderDetailView'), 'outbound-order')),
        permission: [
          'R_ORDER_OVERVIEW_ROOBEO'
        ]
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/home" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/app/home" />
      },
      {
        exact: true,
        path: '/set-password/:id/token/:token',
        component: lazy(() => lazyRetry(() => import('src/views/auth/ResetPassword'), 'set-password'))
      },
      {
        exact: true,
        path: '/recover-password',
        component: lazy(() => lazyRetry(() => import('src/views/auth/ForgotPassword'), 'recover-password'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const Routes: FC = () => {
  const { permissions } = useAuth();

  return renderRoutes(routes, permissions);
};
export default Routes;
