/* eslint-disable */

import mock from 'src/utils/mock';
import wait from 'src/utils/wait';

const tradeItemsDatabase = [
  {
    name: 'Sägeblatt Metall 12 0506',
    gtin: 4010124310127,
    manufacturer_item_number: 120506,
    brand: 'CIMCO',
    manufacturer: 'CIMCO – Werkzeugfabrik Carl Jul. Müller GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [4],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'RZB 10420.002 Wand-Deckenleuchte A60 60W E27 235x235x100 DKN',
    gtin: 4010319018814,
    manufacturer_item_number: 10420002,
    brand: 'RZB',
    manufacturer: 'RZB Rudolf Zimmermann, Bamberg GmbH',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [1],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Deckeneinbau-Präsenzmelder 360Grad,RW ca.8m FB PD-C360i/8 mini UC',
    gtin: 4015120427343,
    manufacturer_item_number: 'EP10427343',
    brand: 'ESYLUX',
    manufacturer: 'Esylux Deutschland GmbH',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [1],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Kanal rws 60x90 RAL9010 HKL6090.3',
    gtin: 4011126348057,
    manufacturer_item_number: 'HKL6090.3',
    brand: 'ESYLUX',
    manufacturer: 'Hermann Kleinhuis GmbH + Co. KG',
    packaging_unit: 'Meter',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [1],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name:
      'Benning DUSPOL digital TRUE RMS Spannung prüfer 1-1000VAC/1200V DC VDE 050263',
    gtin: 4014651502635,
    manufacturer_item_number: 50263,
    brand: 'BENNING',
    manufacturer: 'Benning Elektrotechnik und Elektronik GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [1],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Klein-Läutewerk 80dB 8V weiß LTW 4471A 8V AC',
    gtin: 4011459241117,
    manufacturer_item_number: 24111,
    brand: 'GROTHE',
    manufacturer: 'Grothe GmbH',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [1],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'LED-Lampe 13,5-100W 827 E27NDI CoreLEDBulb#49074700',
    gtin: 8718696490747,
    manufacturer_item_number: 49074700,
    brand: 'PHILIPS',
    manufacturer: 'Signify N.V.',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [1],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Verbinder Buchse/Buchse F02',
    gtin: 4250157700896,
    manufacturer_item_number: 10089,
    brand: 'KREILING',
    manufacturer: 'KREILING Technologien GmbH',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [1],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Zählerfeld komplett BH5, 4Z KA2381',
    gtin: 4011617356394,
    manufacturer_item_number: '2CPX035639R9999',
    brand: 'ESYLUX',
    manufacturer: 'ABB STRIEBEL & JOHN GmbH',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [1],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Leuchtenfassung sw, Lüsterkl. E27 641.1701',
    gtin: 4057298009198,
    manufacturer_item_number: '6.411.701',
    brand: 'BACHMANN',
    manufacturer: 'Scheidt & Bachmann GmbH',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [1],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'DRY Longlife Automatic Pen Graphit 21 2151',
    gtin: 4021104121510,
    manufacturer_item_number: 212151,
    brand: 'CIMCO',
    manufacturer: 'CIMCO – Werkzeugfabrik Carl Jul. Müller GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [4],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Aderendhülse 1,5qmm 472/18',
    gtin: 4012078023757,
    manufacturer_item_number: 47218,
    brand: 'KLAUKE',
    manufacturer: 'Emerson Electric Co.',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [1],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Abgangskabelbaum 10qmm,5-polig,Stift Y89Z',
    gtin: 4010330562228,
    manufacturer_item_number: 'Y89Z',
    brand: 'ELCOM',
    manufacturer: 'Hager Vertriebsgesellschaft mbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [9],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Dres 1135021504,550 JD-79 Spanpl. SchraubePan Head Z verz. 4,5x50',
    gtin: 4001796003752,
    manufacturer_item_number: '1135/021/504,5x50',
    brand: 'DRESSELHAUS',
    manufacturer: 'Joseph Dresselhaus GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [7],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Abisolierzange 10 0686',
    gtin: 4021103006863,
    manufacturer_item_number: 100686,
    brand: 'CIMCO',
    manufacturer: 'CIMCO – Werkzeugfabrik Carl Jul. Müller GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [4],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'VG-Orth 618 Casonic Haftputzgips 25kg Haft u.Leicht',
    gtin: 4003230005285,
    manufacturer_item_number: 618,
    brand: 'HELLERMANNTYTON',
    manufacturer: 'VG-ORTH GmbH & Co.KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [9],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Kabelbinder 4,6x300 sw UB300C-B',
    gtin: 4031026241128,
    manufacturer_item_number: '138-00008',
    brand: 'HELLERMANNTYTON',
    manufacturer: 'HellermannTyton GmbH',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [9],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Gipskartondübel Metall GKMS 12',
    gtin: 4006209404324,
    manufacturer_item_number: 40432,
    brand: 'FISCHER',
    manufacturer: 'fischerwerke GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [9],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Hammerbohrer JORAN SDS-Plus 6x110 20 8308',
    gtin: 4021104083085,
    manufacturer_item_number: 208308,
    brand: 'CIMCO',
    manufacturer: 'CIMCO – Werkzeugfabrik Carl Jul. Müller GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [8],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Kabelbinder 4,5x385 natur UB385C-N-PA66-NA-C1',
    gtin: 5022660329210,
    manufacturer_item_number: '138-00051',
    brand: 'HELLERMANNTYTON',
    manufacturer: 'HellermannTyton GmbH',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [9],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Kabelbinder T30LL-N66-NA-C1',
    gtin: 5022660018534,
    manufacturer_item_number: '111-03529',
    brand: 'HELLERMANNTYTON',
    manufacturer: 'HellermannTyton GmbH',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [9],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Tieflochmarker sw 21 2150',
    gtin: 4021104121503,
    manufacturer_item_number: 212150,
    brand: 'CIMCO',
    manufacturer: 'CIMCO – Werkzeugfabrik Carl Jul. Müller GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [4],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Stiebel HFA-Z 80 WW-Wandspeicher 80l geschlossen 2-6kW weiß 074467',
    gtin: 4017210744675,
    manufacturer_item_number: 74467,
    brand: 'STIEBEL ELTRON',
    manufacturer: 'STIEBEL ELTRON GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [2],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Reinigungstücher 14 5018',
    gtin: 4021103450185,
    manufacturer_item_number: 145018,
    brand: 'CIMCO',
    manufacturer: 'CIMCO – Werkzeugfabrik Carl Jul. Müller GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [4],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Kabelschneider 12 0168',
    gtin: 4021103201688,
    manufacturer_item_number: 120168,
    brand: 'CIMCO',
    manufacturer: 'CIMCO – Werkzeugfabrik Carl Jul. Müller GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [4],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Iso-Nagel-Clip 8,0mm 2008 35 LGR',
    gtin: 4012195217992,
    manufacturer_item_number: 2227622,
    brand: 'OBO BETTERMANN',
    manufacturer: 'OBO Bettermann Holding GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [9],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Iso-Nagel-Clip 5,0mm 2005 25 LGR',
    gtin: 4012195217398,
    manufacturer_item_number: 2227339,
    brand: 'OBO BETTERMANN',
    manufacturer: 'OBO Bettermann Holding GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [9],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Einwegmaske mit Ventil M/L 1030344 (VE10)',
    gtin: 3603830303449,
    manufacturer_item_number: 1030344,
    brand: 'HONEYWELL',
    manufacturer: 'Honeywell International Inc.',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [15],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Aura Atemschutzmaske FFP2,Ausatemventil 9322+ (VE10)',
    gtin: 4046719487219,
    manufacturer_item_number: 7000088722,
    brand: '3M',
    manufacturer: '3M Deutschland GmbH',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [15],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'VG-Orth 196 Casonic Bau-& Elektrikergips 10kg Eimer',
    gtin: 4003230003724,
    manufacturer_item_number: 196,
    brand: 'BROTHER',
    manufacturer: 'VG-ORTH GmbH & Co.KG',
    packaging_unit: 'Kübel',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [9],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Schriftbandkassette BF.weiß/DF.schwarz TZe-231',
    gtin: 4977766685177,
    manufacturer_item_number: 'TZE231',
    brand: 'BROTHER',
    manufacturer: 'Brother International GmbH',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [4],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Zentrierbohrer für Bohrkrone 90 20 8844',
    gtin: 4021104088448,
    manufacturer_item_number: 208844,
    brand: 'CIMCO',
    manufacturer: 'CIMCO – Werkzeugfabrik Carl Jul. Müller GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [8],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Pozdriv-Allzw.schraube 4,5x40 gvz Senkk. 19 0041',
    gtin: 4021103900413,
    manufacturer_item_number: 190041,
    brand: 'CIMCO',
    manufacturer: 'CIMCO – Werkzeugfabrik Carl Jul. Müller GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [7],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Sammelschiene Euro-Vario 63A EVG-3PHAS/12MODUL',
    gtin: 4015082156398,
    manufacturer_item_number: 215639,
    brand: 'EATON',
    manufacturer: 'Eaton Germany GmbH',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [9],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'GE Stand-WC flach PARIS 98 004010 weiß',
    gtin: 3427340558607,
    manufacturer_item_number: 4010000,
    brand: 'GEBERIT',
    manufacturer: 'Geberit International AG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [2],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Dübel DUOPOWER 10x80 538242',
    gtin: 4048962262094,
    manufacturer_item_number: 538242,
    brand: 'FISCHER',
    manufacturer: 'fischerwerke GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [9],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Brandschutz-Schraubanker Sechskant SW10 MMS-plus ST 6x55',
    gtin: 4012196559671,
    manufacturer_item_number: 3498264,
    brand: 'OBO BETTERMANN',
    manufacturer: 'OBO Bettermann Holding GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [7],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name:
      'Fischer 050354 Nageldübel N 6x40/10 S (50) Nagelschraube Schachtelware',
    gtin: 4006209503546,
    manufacturer_item_number: 50354,
    brand: 'FISCHER',
    manufacturer: 'fischerwerke GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [9],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Brandschutz-Schraubanker Panhead, Antrieb T30 MMS-plus 6X50',
    gtin: 4012196559657,
    manufacturer_item_number: 3498108,
    brand: 'OBO BETTERMANN',
    manufacturer: 'OBO Bettermann Holding GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [7],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'HELLMICH Wannenträger Vario 1700x700mm',
    gtin: 4005387198063,
    manufacturer_item_number: 17014428,
    brand: 'PORESTA SYSTEMS',
    manufacturer: 'poresta systems GmbH',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [2],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'GROHE Brausestangenset Tempesta 100 27646 2 Strahlarten 900mm chrom',
    gtin: 4005176985522,
    manufacturer_item_number: 27646001,
    brand: 'GROHE',
    manufacturer: 'GROHE Deutschland Vertriebs GmbH',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [2],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Durchlauferhitzer electronic, 21kW VED E 21/7',
    gtin: 4024074570135,
    manufacturer_item_number: 10007728,
    brand: 'VAILLANT',
    manufacturer: 'Vaillant Deutschland GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [2],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Pozdriv-Allzw.schraube 4,0x40 gvz Senkk. 19 0029',
    gtin: 4021103900291,
    manufacturer_item_number: 190029,
    brand: 'CIMCO',
    manufacturer: 'CIMCO – Werkzeugfabrik Carl Jul. Müller GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [7],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Impu-Fix-Stegleit.kleber 509 /650gr.',
    gtin: 4012195228073,
    manufacturer_item_number: 2341018,
    brand: 'OBO BETTERMANN',
    manufacturer: 'OBO Bettermann Holding GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [9],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Mauernutschelle Typ A für LD402',
    gtin: 4011890083314,
    manufacturer_item_number: 303673,
    brand: 'RHODIUS',
    manufacturer: 'RHODIUS Schleifwerkzeuge GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [15],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Pozdriv-Allzw.schraube 5,0x30 gvz Halbrund. 19 0118',
    gtin: 4021103901182,
    manufacturer_item_number: 190118,
    brand: 'CIMCO',
    manufacturer: 'CIMCO – Werkzeugfabrik Carl Jul. Müller GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [7],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Geräteschraube, PlusMinus 3,2x25mm, gvz 2472-25',
    gtin: 4013456548770,
    manufacturer_item_number: '2472-25',
    brand: 'KAISER',
    manufacturer: 'Kaiser GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [7],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Papierfilterbeutel f.GAS25 2 605 411 167(VE5)',
    gtin: 3165140257985,
    manufacturer_item_number: 2605411167,
    brand: 'BROTHER',
    manufacturer: 'Robert Bosch Power Tools GmbH',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [15],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Golden-Sprintschraube 4758T 4.0x30',
    gtin: 4012195555551,
    manufacturer_item_number: 3198036,
    brand: 'OBO BETTERMANN',
    manufacturer: 'OBO Bettermann Holding GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [7],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'ORTH Ansetzgips 25kg',
    gtin: 4003230005292,
    manufacturer_item_number: 412,
    brand: 'BROTHER',
    manufacturer: 'VG-ORTH GmbH & Co.KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [9],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Diamanttrennscheibe D=125mm 20 8710',
    gtin: 4021104087106,
    manufacturer_item_number: 208710,
    brand: 'CIMCO',
    manufacturer: 'CIMCO – Werkzeugfabrik Carl Jul. Müller GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [8],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Allcon 10 310ml Kartusche 260100310',
    gtin: 4036421260101,
    manufacturer_item_number: 260100310,
    brand: 'BEKO',
    manufacturer: 'beko GmbH',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [9],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Montagezement 14 0346 VE 7kg',
    gtin: 4003230003564,
    manufacturer_item_number: 140346,
    brand: 'CIMCO',
    manufacturer: 'CIMCO – Werkzeugfabrik Carl Jul. Müller GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [9],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Stufenbohrer HSS 8-40,5mm 12 Stufen 20 1209',
    gtin: 4021104012092,
    manufacturer_item_number: 201209,
    brand: 'CIMCO',
    manufacturer: 'CIMCO – Werkzeugfabrik Carl Jul. Müller GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [8],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'HELLMICH Wannenträger Vario 1600x700mm',
    gtin: 4005387198049,
    manufacturer_item_number: 17014426,
    brand: 'PORESTA SYSTEMS',
    manufacturer: 'poresta systems GmbH',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [2],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Schraubendrehersatz SoftFinish 3201SF K12 S.12tlg',
    gtin: 4010995410032,
    manufacturer_item_number: 41003,
    brand: 'WIHA',
    manufacturer: 'Wiha Werkzeuge GmbH',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [4],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'VG-Orth 149 Casonic Bau- u.Elektrikergips 25kg',
    gtin: 4003230002994,
    manufacturer_item_number: 149,
    brand: 'BROTHER',
    manufacturer: 'VG-ORTH GmbH & Co.KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [9],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Lochsägen-Sortiment je 1 35/68/75mm 20 7400',
    gtin: 4021104074007,
    manufacturer_item_number: 207400,
    brand: 'CIMCO',
    manufacturer: 'CIMCO – Werkzeugfabrik Carl Jul. Müller GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [4],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Schraubendreher-Set 18-tlg 2831T18',
    gtin: 4010995412319,
    manufacturer_item_number: 41231,
    brand: 'WIHA',
    manufacturer: 'Wiha Werkzeuge GmbH',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [4],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Einwegmaske ohne Filter M/L 1030341 (VE5)',
    gtin: 3603830303418,
    manufacturer_item_number: 1030341,
    brand: 'HONEYWELL',
    manufacturer: 'Honeywell International Inc.',
    packaging_unit: 'Karton(s)',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [15],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Diamant-Trennscheibe 140mm Set X-Treme 610093 (VE2)',
    gtin: 3439516100930,
    manufacturer_item_number: 610093,
    brand: 'SPIT',
    manufacturer: 'ITW Befestigungssysteme GmbH',
    packaging_unit: 'Set',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [8],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Standard-Wärmespeicher 5kW/400V, weiss ETS 500 Plus',
    gtin: 4017212364277,
    manufacturer_item_number: 236427,
    brand: 'STIEBEL ELTRON',
    manufacturer: 'STIEBEL ELTRON GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [2],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Lösungsmittelgemisch 508 /1000ml',
    gtin: 4012195228196,
    manufacturer_item_number: 2341107,
    brand: 'OBO BETTERMANN',
    manufacturer: 'OBO Bettermann Holding GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [9],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Bau+Elektrikergips im Eimer 14 0335 VE10kg',
    gtin: 4003230003724,
    manufacturer_item_number: 140335,
    brand: 'CIMCO',
    manufacturer: 'CIMCO – Werkzeugfabrik Carl Jul. Müller GmbH & Co. KG',
    packaging_unit: 'Kübel',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [9],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'GE WC-Sitz Renova Nr. 1 PLAN 572110 weiß,Edelstahlscharnier',
    gtin: 4022009305661,
    manufacturer_item_number: 572110000,
    brand: 'GEBERIT',
    manufacturer: 'Geberit International AG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [2],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'GE Wand-WC RENOVA Nr. 1 PLAN 202150 tief, weiß',
    gtin: 4022009305746,
    manufacturer_item_number: 202150000,
    brand: 'GEBERIT',
    manufacturer: 'Geberit International AG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [2],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Diamanttrennscheibe D=115mm 20 8708',
    gtin: 4021104087083,
    manufacturer_item_number: 208708,
    brand: 'CIMCO',
    manufacturer: 'CIMCO – Werkzeugfabrik Carl Jul. Müller GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [8],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Brandschutzkanal-Mörtel 3,5kg, grau BSK-M',
    gtin: 4012196888214,
    manufacturer_item_number: 7215500,
    brand: 'OBO BETTERMANN',
    manufacturer: 'OBO Bettermann Holding GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [9],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Diamanttrennscheibe D=125mm 20 8754',
    gtin: 4021104087540,
    manufacturer_item_number: 208754,
    brand: 'CIMCO',
    manufacturer: 'CIMCO – Werkzeugfabrik Carl Jul. Müller GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [8],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Diamantbohrkrone 20 8796',
    gtin: 4021104087960,
    manufacturer_item_number: 208796,
    brand: 'CIMCO',
    manufacturer: 'CIMCO – Werkzeugfabrik Carl Jul. Müller GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [8],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Arbeitshandschuh Grip Größe 10 905020110',
    gtin: 4036421905170,
    manufacturer_item_number: 905020110,
    brand: 'BEKO',
    manufacturer: 'beko GmbH',
    packaging_unit: 'Paar',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [15],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Durchlauferhitzer 21kw, elektronisch DHB 21 ST thermotron',
    gtin: 4017212276099,
    manufacturer_item_number: 227609,
    brand: 'STIEBEL ELTRON',
    manufacturer: 'STIEBEL ELTRON GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [2],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Bohrkrone Diamant-Trocken 2402',
    gtin: 4046382024025,
    manufacturer_item_number: 2402,
    brand: 'BROTHER',
    manufacturer: 'BAIER GmbH + Co KG Maschinenfabrik',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [8],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Diamant-Trockenbohrkrone ABS. 82/85 654515',
    gtin: 3439516545151,
    manufacturer_item_number: 654515,
    brand: 'SPIT',
    manufacturer: 'ITW Befestigungssysteme GmbH',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [8],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Müllbeutel 120l, blau 25 Sack auf Rolle 14 5001',
    gtin: 4021103450017,
    manufacturer_item_number: 145001,
    brand: 'CIMCO',
    manufacturer: 'CIMCO – Werkzeugfabrik Carl Jul. Müller GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [15],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Abdeckvlies Standard 1x25m 14 5010',
    gtin: 4021103450109,
    manufacturer_item_number: 145010,
    brand: 'CIMCO',
    manufacturer: 'CIMCO – Werkzeugfabrik Carl Jul. Müller GmbH & Co. KG',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [15],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Prüfetiketten 30mm 1239 (VE250)',
    gtin: 95969258951,
    manufacturer_item_number: 2145963,
    brand: 'FLUKE',
    manufacturer: 'Fluke Deutschland GmbH',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [15],
    is_active: true,
    tax_rate: '16%'
  },
  {
    name: 'Aura Atemschutzmaske FFP1,Ausatemventil 9312+ (VE10)',
    gtin: 4046719487370,
    manufacturer_item_number: 7000088724,
    brand: '3M',
    manufacturer: '3M Deutschland GmbH',
    packaging_unit: 'Stück',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    category: [15],
    is_active: true,
    tax_rate: '16%'
  }
];

const descendingComparator = (a: any, b: any, orderBy: string): number => {
  if (b.attributes[orderBy] < a.attributes[orderBy]) {
    return -1;
  }

  if (b.attributes[orderBy] > a.attributes[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order: 'asc' | 'desc', orderBy: string) => {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
};

const applySort = (tradeitems: any[], order: any, orderBy: string): any[] => {
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = tradeitems.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    // @ts-ignore
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    // @ts-ignore
    return a[1] - b[1];
  });

  // @ts-ignore
  return stabilizedThis.map((el) => el[0]);
};

const applyPagination = (
  tradeitems: any[],
  page: number,
  limit: number
): any[] => {
  page -= 1;
  return tradeitems.slice(page * limit, page * limit + limit);
};

const applyFilters = (tradeitems: any[], query: string, queryField): any[] => {
  return tradeitems.filter((tradeitem) => {
    let matches = true;
    let queryFields = [];

    switch (queryField) {
      case 'all':
        queryFields = [
          'name',
          'trade_item_id',
          'gtin',
          'manufacturer_item_number'
        ];
        break;
      case 'GTIN':
        queryFields = ['gtin'];
        break;
      case 'name':
        queryFields = ['name'];
        break;
      case 'min':
        queryFields = ['manufacturer_item_number'];
        break;

      default:
        queryFields = [
          'name',
          'trade_item_id',
          'gtin',
          'manufacturer_item_number'
        ];
    }

    if (query) {
      let containsQuery = false;

      queryFields.forEach((property) => {
        if (
          tradeitem.attributes[property]
            .toString()
            .toLowerCase()
            .includes(query.toLowerCase())
        ) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    return matches;
  });
};

const applyCategoryFilter = (tradeitems: any[], categoryId: number): any[] => {
  return tradeitems.filter((tradeitem) => {
    let matches = true;

    if (categoryId) {
      let containsQuery = false;

      if (tradeitem.attributes.category.indexOf(categoryId) > -1) {
        containsQuery = true;
      }

      if (!containsQuery) {
        matches = false;
      }
    }

    return matches;
  });
};

const applySingleFilter = (tradeitems: any[], field, value) => {
  return tradeitems.filter((tradeitem) => {
    let matches = true;

    if (value) {
      let containsQuery = false;

      value.split(',').forEach((item) => {
        if (tradeitem.attributes[field].toLowerCase() === item.toLowerCase()) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    return matches;
  });
};

function getQueryVariable(variable: string, query: string) {
  const cut = query.slice(query.indexOf('?') + 1);
  const vars = cut.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
}

export function generateTradeItems(qty: number) {
  const tradeItems: any[] = [];

  let j = 1000;
  for (let i = 1; i <= qty; i++) {
    const tradeitem = {
      type: 'tradeitems',
      id: j,
      attributes: {
        trade_item_id: j,
        ...tradeItemsDatabase[i]
      },
      links: {
        self: '....'
      }
    };
    tradeItems.push(tradeitem);
    j++;
  }

  return tradeItems;
}

const tradeitemsDb = generateTradeItems(77);

export const wrapAsJsonAPI = (data, numberOfRecords) => {
  return {
    data,
    links: {
      next: '/api/v2/tradeitems?pageNumber=2&numberOfItemsPerPage=5',
      last: '/api/v2/tradeitems?pageNumber=2&numberOfItemsPerPage=5',
      self: '/api/v2/tradeitems?pageNumber=1&numberOfItemsPerPage=5'
    },
    meta: {
      number_of_items: numberOfRecords,
      number_of_pages: 1
    },
    jsonapi: {
      version: '1.0'
    }
  };
};

const tradeitemRegex = /api\/tradeitem\/\d+$/;
mock.onGet(tradeitemRegex).reply((config) => {
  const tradeitem = generateTradeItems(1);
  const output = wrapAsJsonAPI(tradeitem, 1);

  return [200, { ...output }];
});

mock.onGet(new RegExp('/api/tradeitems')).reply((config) => {
  const currentPage = parseInt(getQueryVariable('pageNumber', config.url));
  const pageSize = parseInt(
    getQueryVariable('numberOfItemsPerPage', config.url)
  );
  const orderBy = getQueryVariable('sortedBy', config.url);
  const order = getQueryVariable('sortDirection', config.url);
  const query = getQueryVariable('q', config.url);
  const searchField = getQueryVariable('searchField', config.url);
  const brandFilter = getQueryVariable('brandFilter', config.url);
  const manufacturerFilter = getQueryVariable('manufacturerFilter', config.url);
  const customerGroupFilter = getQueryVariable(
    'customerGroupFilter',
    config.url
  );
  const supplierFilter = getQueryVariable('supplierFilter', config.url);
  const supplierItemNumber = getQueryVariable('supplierItemNumber', config.url);
  const isActiveTradeItemFilter = getQueryVariable(
    'isActiveTradeItemFilter',
    config.url
  );
  const isWarehouseItemFilter = getQueryVariable(
    'isWarehouseItemFilter',
    config.url
  );
  const categoryFilter = parseInt(
    getQueryVariable('categoryFilter', config.url)
  );


  const filtered = tradeitemsDb.filter(
    (item) => item.attributes.is_deleted !== true
  );
  const appliedQuery = applyFilters(filtered, query, searchField);
  const appliedCategoryFilter = applyCategoryFilter(
    appliedQuery,
    categoryFilter
  );
  const appliedBrandFilter = applySingleFilter(
    appliedCategoryFilter,
    'brand',
    brandFilter
  );

  const appliedManufacturerFilter = applySingleFilter(
    appliedBrandFilter,
    'manufacturer',
    manufacturerFilter
  );

  const sorted = applySort(appliedManufacturerFilter, order, orderBy);
  const paginated = applyPagination(sorted, currentPage, pageSize);

  const numberOfRecords = query || categoryFilter ? paginated.length : 77;

  const tradeitems = wrapAsJsonAPI(paginated, numberOfRecords);
  return [200, { ...tradeitems }];
});
mock.onPost('/api/tradeitem/update').reply(async (config) => {
  try {
    await wait(1000);
    return [200, { status: 'ok' }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});

mock.onPost('/api/tradeitem/delete').reply(async (config) => {
  try {
    await wait(1000);
    return [200, { status: 'ok' }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});
