import { setLocale } from 'yup';
import { IntlShape } from 'react-intl';
import enMessages from './translations/en.json';
import deMessages from './translations/de.json';

type Locale = {
  locale: string;
  messages: any;
  timeZone: string;
};

interface Locales {
  [key: string]: Locale;
}

const locales: Locales = {
  en: {
    locale: 'en',
    messages: enMessages,
    timeZone: 'Europe/London'
  },
  de: {
    locale: 'de',
    messages: deMessages,
    timeZone: 'Europe/Berlin'
  }
};

export const getLocaleData = (locale: string): Locale => (locales[locale.toLowerCase()]
  ? locales[locale.toLowerCase()] : locales.en);

export const setYupLocale = (intl:IntlShape): void => {
  setLocale({
    mixed: {
      default: ({ path }) => intl.formatMessage(
        { id: 'form.mixed.default', defaultMessage: 'is invalid' },
        { path }
      ),
      required: ({ path }) => intl.formatMessage(
        { id: 'form.mixed.required', defaultMessage: 'is a required field' },
        { path }
      ),
      oneOf: ({ values, path }) => intl.formatMessage(
        {
          id: 'form.mixed.oneof',
          defaultMessage: 'must be one of the following values: {values}'
        },
        { values, path }
      ),
      notOneOf: ({ values, path }) => intl.formatMessage(
        {
          id: 'form.mixed.notoneof',
          defaultMessage: 'must not be one of the following values: {values}'
        },
        { values, path }
      )
    },
    string: {
      min: ({ min, path }) => intl.formatMessage(
        {
          id: 'form.string.min',
          defaultMessage: 'must be at least {min} characters'
        },
        { min, path }
      ),
      length: ({ length, path }) => intl.formatMessage(
        {
          id: 'form.string.length',
          defaultMessage: 'must be exactly {length} characters'
        },
        { length, path }
      ),
      max: ({ max, path }) => intl.formatMessage(
        {
          id: 'form.string.max',
          defaultMessage: 'must be at most {max} characters'
        },
        { max, path }
      ),
      matches: ({ regex, path }) => intl.formatMessage(
        {
          id: 'form.string.match',
          defaultMessage: 'must match the following: {regex}'
        },
        { regex, path }
      ),
      email: ({ path }) => intl.formatMessage(
        { id: 'form.string.email', defaultMessage: 'must be a valid email' },
        { path }
      ),
      url: ({ path }) => intl.formatMessage(
        { id: 'form.string.url', defaultMessage: 'must be a valid URL' },
        { path }
      ),
      trim: ({ path }) => intl.formatMessage(
        {
          id: 'form.string.trim',
          defaultMessage: 'must be a trimmed string'
        },
        { path }
      ),
      lowercase: ({ path }) => intl.formatMessage(
        {
          id: 'form.string.lowercase',
          defaultMessage: 'must be a lowercase string'
        },
        { path }
      ),
      uppercase: ({ path }) => intl.formatMessage(
        {
          id: 'form.string.uppercase',
          defaultMessage: 'must be a upper case string'
        },
        { path }
      )
    },
    number: {
      min: ({ min, path }) => intl.formatMessage(
        {
          id: 'form.number.min',
          defaultMessage: 'must be greater than or equal to {min}'
        },
        { min, path }
      ),
      max: ({ max, path }) => intl.formatMessage(
        {
          id: 'form.number.max',
          defaultMessage: 'must be less than or equal to {max}'
        },
        { max, path }
      ),
      lessThan: ({ less, path }) => intl.formatMessage(
        {
          id: 'form.number.less',
          defaultMessage: 'must be less than {less}'
        },
        { less, path }
      ),
      moreThan: ({ more, path }) => intl.formatMessage(
        {
          id: 'form.number.more',
          defaultMessage: 'must be greater than {more}'
        },
        { more, path }
      ),

      positive: ({ path }) => intl.formatMessage(
        {
          id: 'form.number.positive',
          defaultMessage: 'must be a positive number'
        },
        { path }
      ),
      negative: ({ path }) => intl.formatMessage(
        {
          id: 'form.number.negative',
          defaultMessage: 'must be a negative number'
        },
        { path }
      ),
      integer: ({ path }) => intl.formatMessage(
        { id: 'form.number.integer', defaultMessage: 'must be an integer' },
        { path }
      )
    },
    date: {
      min: ({ min, path }) => intl.formatMessage(
        {
          id: 'form.date.min',
          defaultMessage: 'field must be later than {min}'
        },
        { min, path }
      ),
      max: ({ max, path }) => intl.formatMessage(
        {
          id: 'form.date.max',
          defaultMessage: 'field must be at earlier than {max}'
        },
        { max, path }
      )
    },
    object: {
      noUnknown: ({ path }) => intl.formatMessage(
        {
          id: 'form.object.min',
          defaultMessage: 'field has unspecified keys: {unknown}'
        },
        { path }
      )
    },
    array: {
      min: ({ min, path }) => intl.formatMessage(
        {
          id: 'form.array.min',
          defaultMessage: 'field must have at least {min} items'
        },
        { min, path }
      ),
      max: ({ max, path }) => intl.formatMessage(
        {
          id: 'form.array.max',
          defaultMessage: 'field must have less than or equal to {max} items'
        },
        { max, path }
      )
    }
  });
};
