/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation, matchPath, Link as RouterLink } from 'react-router-dom';

import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles
} from '@material-ui/core';
import {
  Users as UsersIcon,
  Package as PackageIcon,
  FileText as OrdersIcon,
  TrendingUp,
  Home
} from 'react-feather';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import Can from 'src/components/Can';
import { useIntl } from 'react-intl';
import NavItem from './NavItem';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  keyName?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
  permission?: string[];
}

interface Section {
  items: Item[];
  subheader: string;
}

function renderNavItems({
  items,
  pathname,
  depth = 0,
  permissions
}: {
  items: Item[];
  pathname: string;
  depth?: number;
  permissions: any;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        (acc, item) => reduceChildRoutes({
          acc, item, pathname, depth, permissions
        }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
  permissions
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
  permissions: any;
}) {
  const key = item.keyName || item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <Can
        key={key}
        userPermissions={permissions || ''}
        perform={item.permission}
        yes={() => (
          <NavItem
            depth={depth}
            icon={item.icon}
            info={item.info}
            key={key}
            open={Boolean(open)}
            title={item.title}
          >
            {renderNavItems({
              depth: depth + 1,
              pathname,
              items: item.items,
              permissions
            })}
          </NavItem>
        )}
        no={() => null}
      />
    );
  } else {
    acc.push(
      <Can
        key={key}
        userPermissions={permissions || ''}
        perform={item.permission}
        yes={() => (
          <NavItem
            depth={depth}
            href={item.href}
            icon={item.icon}
            info={item.info}
            key={key}
            title={item.title}
          />
        )}
        no={() => null}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 240,
    position: 'relative'
  },
  desktopDrawer: {
    width: 240,
    top: 64,
    height: 'calc(100% - 64px)',
    position: 'relative'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { permissions } = useAuth();
  const intl = useIntl();

  const sections: Section[] = [
    {
      subheader: 'Apps',
      items: [
        {
          title: intl.formatMessage({
            id: '*.app.home',
            defaultMessage: 'Home'
          }),
          icon: Home,
          href: '/app/home'
        },
        {
          title: intl.formatMessage({
            id: '*.userManagement',
            defaultMessage: 'User Management'
          }),
          icon: UsersIcon,
          href: '/app/management/partners',
          permission: ['R_USER_MANAGEMENT'],

          items: [
            {
              title: intl.formatMessage({
                id: '*.managePartner',
                defaultMessage: 'Manage Partner'
              }),
              href: '/app/management/partners',
              permission: ['CRUD_PARTNER_ACCOUNT']
            },
            {
              title: intl.formatMessage({
                id: '*.manageUser',
                defaultMessage: 'Manage User'
              }),
              href: '/app/management/users',
              permission: ['CRUD_OWN_PARTNER_ACCOUNT']
            }
          ]
        },
        {
          title: intl.formatMessage({
            id: '*.orderManagement',
            defaultMessage: 'Order Management'
          }),
          icon: OrdersIcon,
          href: '/app/management/orders/overview',
          permission: ['R_ORDER_MANAGEMENT', 'R_ORDER_OVERVIEW_ROOBEO']
        },
        {
          title: intl.formatMessage({
            id: '*.app.insights.customer',
            defaultMessage: 'insights'
          }),
          keyName: 'insights-customer',
          icon: TrendingUp,
          href: '/app/reporting/insights/customer',
          permission: ['INS_CMR']
        },
        {
          title: intl.formatMessage({
            id: '*.app.insights.supplier',
            defaultMessage: 'insights'
          }),
          keyName: 'insights-supplier',
          icon: TrendingUp,
          href: '/app/reporting/insights/supplier',
          permission: ['INS_SUP']
        },
        {
          title: intl.formatMessage({
            id: '*.app.insights.partner',
            defaultMessage: 'insights'
          }),
          keyName: 'insights-partner',
          icon: TrendingUp,
          href: '/app/reporting/insights/partner',
          permission: ['INS_PNR']
        },
        {
          title: intl.formatMessage({
            id: '*.app.insights.internal',
            defaultMessage: 'Insights (Internal)'
          }),
          keyName: 'insights-internal',
          icon: TrendingUp,
          href: '/app/reporting/insights/internal',
          permission: ['INS_INT']
        },
        {
          title: intl.formatMessage({
            id: '*.app.accelerate',
            defaultMessage: 'accelerate'
          }),
          keyName: 'accelerate',
          icon: TrendingUp,
          href: '/app/reporting/accelerate',
          permission: ['CAN_ACCELERATE']
        }
      ]
    }
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
                permissions
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
