import React, { useEffect } from 'react';
import type { FC } from 'react';
import NProgress from 'nprogress';
import {
  Box, LinearProgress, makeStyles, Typography
} from '@material-ui/core';
import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3)
  },
  message: {

  }
}));

interface LoadingScreenProps {
  id?: string;
  description?: string;
}

const LoadingScreen: FC<LoadingScreenProps> = ({ id, description }) => {
  const classes = useStyles();

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div className={classes.root} id={id}>
      <Box data-testid="loading-screen" width={400}>
        <LinearProgress />
        {description ? (
          <Typography>
            {description}
          </Typography>
        ) : (
          <>
          </>
        )}
      </Box>
    </div>
  );
};

export default LoadingScreen;
