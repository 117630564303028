/* eslint-disable */
import mock from 'src/utils/mock';
import wait from 'src/utils/wait';

const descendingComparator = (a: any, b: any, orderBy: string): number => {
  if (b.attributes[orderBy] < a.attributes[orderBy]) {
    return -1;
  }

  if (b.attributes[orderBy] > a.attributes[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order: 'asc' | 'desc', orderBy: string) => {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
};

const applySort = (users: any[], order: any, orderBy: string): any[] => {
  if (orderBy === 'name') {
    orderBy = 'first_name';
  }
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = users.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    return a[1] - b[1];
  });

  // @ts-ignore
  return stabilizedThis.map((el) => el[0]);
};

const applyPagination = (users: any[], page: number, limit: number): any[] => {
  page -= 1;
  return users.slice(page * limit, page * limit + limit);
};

const applyFilters = (users: any[], query: string): any[] => {
  return users.filter((user) => {
    let matches = true;

    if (query) {
      const properties = [
        'first_name',
        'last_name',
        'email',
        'user_id',
        'job_description'
      ];
      let containsQuery = false;

      properties.forEach((property) => {
        if (
          user.attributes[property]
            .toString()
            .toLowerCase()
            .includes(query.toLowerCase())
        ) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    return matches;
  });
};

function getQueryVariable(variable: string, query: string) {
  const cut = query.slice(query.indexOf('?') + 1);
  const vars = cut.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
}

export function generateSampleOrders() {
  const orders = {
    meta: {
      page: {
        'current-page': 1,
        'per-page': 10,
        from: 1,
        to: 10,
        total: 1237,
        'last-page': 124
      }
    },
    links: {
      first:
        'https://order-management.stage.roobeo.com/api/v1/outbound-orders?sort=-order_at&page%5Bnumber%5D=1&page%5Bsize%5D=10',
      next:
        'https://order-management.stage.roobeo.com/api/v1/outbound-orders?sort=-order_at&page%5Bnumber%5D=2&page%5Bsize%5D=10',
      last:
        'https://order-management.stage.roobeo.com/api/v1/outbound-orders?sort=-order_at&page%5Bnumber%5D=124&page%5Bsize%5D=10'
    },
    data: [
      {
        type: 'outbound-orders',
        id: 'A5PP4BA9FS',
        attributes: {
          outbound_order_id: 'A5PP4BA9FS',
          contact_person: 'contact_test',
          currency: 'EUR',
          order_total: 8,
          order_reference: 'TestRef1',
          shipping_cost: 2.95,
          shipment_type: 'default_shipment',
          outbound_order_status: 'CONFIRMED',
          requested_delivery_date: '2021-02-10T16:40:00+00:00',
          order_date: '2021-01-29T18:30:00+00:00',
          confirmation_date: '2021-02-01T08:20:00+00:00',
          supplier_id: 419,
          supplier_name: '419',
          customer_group_partner_id: 460,
          customer_group_partner_name: 'Vonovia',
          confirmation_asset_id: null
        },
        relationships: {
          order_lines: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/relationships/order_lines',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/order_lines'
            }
          },
          order_document: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/relationships/order_document',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/order_document'
            }
          },
          customer: {
            data: {
              type: 'customer',
              id: '2890'
            },
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/relationships/customer',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/customer'
            }
          },
          invoice_recipient: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/relationships/invoice_recipient',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/invoice_recipient'
            }
          },
          shipment_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/relationships/shipment_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/shipment_address'
            }
          },
          invoice_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/relationships/invoice_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/invoice_address'
            }
          },
          supplier_main_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/relationships/supplier_main_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/supplier_main_address'
            }
          },
          shipments: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/relationships/shipments',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/shipments'
            }
          },
          shipment_lines: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/relationships/shipment_lines',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/shipment_lines'
            }
          }
        },
        links: {
          self:
            'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS'
        }
      },
      {
        type: 'outbound-orders',
        id: 'A5PQ7TJ9RC',
        attributes: {
          outbound_order_id: 'A5PQ7TJ9RC',
          contact_person: 'contact_test',
          currency: 'EUR',
          order_total: 8,
          order_reference: 'TestRef1',
          shipping_cost: 2.95,
          shipment_type: 'default_shipment',
          outbound_order_status: 'OPEN',
          requested_delivery_date: '2021-02-10T16:40:00+00:00',
          order_date: '2021-01-29T18:30:00+00:00',
          confirmation_date: null,
          supplier_id: 419,
          supplier_name: '419',
          customer_group_partner_id: 460,
          customer_group_partner_name: 'Vonovia',
          confirmation_asset_id: null
        },
        relationships: {
          order_lines: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQ7TJ9RC/relationships/order_lines',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQ7TJ9RC/order_lines'
            }
          },
          order_document: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQ7TJ9RC/relationships/order_document',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQ7TJ9RC/order_document'
            }
          },
          customer: {
            data: {
              type: 'customer',
              id: '2892'
            },
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQ7TJ9RC/relationships/customer',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQ7TJ9RC/customer'
            }
          },
          invoice_recipient: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQ7TJ9RC/relationships/invoice_recipient',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQ7TJ9RC/invoice_recipient'
            }
          },
          shipment_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQ7TJ9RC/relationships/shipment_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQ7TJ9RC/shipment_address'
            }
          },
          invoice_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQ7TJ9RC/relationships/invoice_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQ7TJ9RC/invoice_address'
            }
          },
          supplier_main_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQ7TJ9RC/relationships/supplier_main_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQ7TJ9RC/supplier_main_address'
            }
          },
          shipments: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQ7TJ9RC/relationships/shipments',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQ7TJ9RC/shipments'
            }
          },
          shipment_lines: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQ7TJ9RC/relationships/shipment_lines',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQ7TJ9RC/shipment_lines'
            }
          }
        },
        links: {
          self:
            'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQ7TJ9RC'
        }
      },
      {
        type: 'outbound-orders',
        id: 'A5PP3ZGYWC',
        attributes: {
          outbound_order_id: 'A5PP3ZGYWC',
          contact_person: 'contact_test',
          currency: 'EUR',
          order_total: 8,
          order_reference: 'TestRef1',
          shipping_cost: 2.95,
          shipment_type: 'default_shipment',
          outbound_order_status: 'CONFIRMED',
          requested_delivery_date: '2021-02-10T16:40:00+00:00',
          order_date: '2021-01-29T17:50:00+00:00',
          confirmation_date: '2021-01-29T12:15:13+00:00',
          supplier_id: 419,
          supplier_name: '419',
          customer_group_partner_id: 460,
          customer_group_partner_name: 'Vonovia',
          confirmation_asset_id: null
        },
        relationships: {
          order_lines: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3ZGYWC/relationships/order_lines',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3ZGYWC/order_lines'
            }
          },
          order_document: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3ZGYWC/relationships/order_document',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3ZGYWC/order_document'
            }
          },
          customer: {
            data: {
              type: 'customer',
              id: '2888'
            },
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3ZGYWC/relationships/customer',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3ZGYWC/customer'
            }
          },
          invoice_recipient: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3ZGYWC/relationships/invoice_recipient',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3ZGYWC/invoice_recipient'
            }
          },
          shipment_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3ZGYWC/relationships/shipment_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3ZGYWC/shipment_address'
            }
          },
          invoice_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3ZGYWC/relationships/invoice_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3ZGYWC/invoice_address'
            }
          },
          supplier_main_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3ZGYWC/relationships/supplier_main_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3ZGYWC/supplier_main_address'
            }
          },
          shipments: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3ZGYWC/relationships/shipments',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3ZGYWC/shipments'
            }
          },
          shipment_lines: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3ZGYWC/relationships/shipment_lines',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3ZGYWC/shipment_lines'
            }
          }
        },
        links: {
          self:
            'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3ZGYWC'
        }
      },
      {
        type: 'outbound-orders',
        id: 'A5PP3KGMGY',
        attributes: {
          outbound_order_id: 'A5PP3KGMGY',
          contact_person: 'contact_test',
          currency: 'EUR',
          order_total: 8,
          order_reference: 'TestRef1',
          shipping_cost: 2.95,
          shipment_type: 'default_shipment',
          outbound_order_status: 'CONFIRMED',
          requested_delivery_date: '2021-02-10T16:40:00+00:00',
          order_date: '2021-01-29T17:40:00+00:00',
          confirmation_date: '2021-01-30T14:01:00+00:00',
          supplier_id: 419,
          supplier_name: '419',
          customer_group_partner_id: 460,
          customer_group_partner_name: 'Vonovia',
          confirmation_asset_id: null
        },
        relationships: {
          order_lines: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3KGMGY/relationships/order_lines',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3KGMGY/order_lines'
            }
          },
          order_document: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3KGMGY/relationships/order_document',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3KGMGY/order_document'
            }
          },
          customer: {
            data: {
              type: 'customer',
              id: '2885'
            },
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3KGMGY/relationships/customer',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3KGMGY/customer'
            }
          },
          invoice_recipient: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3KGMGY/relationships/invoice_recipient',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3KGMGY/invoice_recipient'
            }
          },
          shipment_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3KGMGY/relationships/shipment_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3KGMGY/shipment_address'
            }
          },
          invoice_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3KGMGY/relationships/invoice_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3KGMGY/invoice_address'
            }
          },
          supplier_main_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3KGMGY/relationships/supplier_main_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3KGMGY/supplier_main_address'
            }
          },
          shipments: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3KGMGY/relationships/shipments',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3KGMGY/shipments'
            }
          },
          shipment_lines: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3KGMGY/relationships/shipment_lines',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3KGMGY/shipment_lines'
            }
          }
        },
        links: {
          self:
            'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3KGMGY'
        }
      },
      {
        type: 'outbound-orders',
        id: 'A5PP3B8V9T',
        attributes: {
          outbound_order_id: 'A5PP3B8V9T',
          contact_person: 'contact_test',
          currency: 'EUR',
          order_total: 8,
          order_reference: 'TestRef1',
          shipping_cost: 2.95,
          shipment_type: 'default_shipment',
          outbound_order_status: 'RECEIVED',
          requested_delivery_date: '2021-02-10T16:40:00+00:00',
          order_date: '2021-01-29T16:40:00+00:00',
          confirmation_date: '2021-01-29T13:45:00+00:00',
          supplier_id: 419,
          supplier_name: '419',
          customer_group_partner_id: 460,
          customer_group_partner_name: 'Vonovia',
          confirmation_asset_id: null
        },
        relationships: {
          order_lines: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3B8V9T/relationships/order_lines',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3B8V9T/order_lines'
            }
          },
          order_document: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3B8V9T/relationships/order_document',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3B8V9T/order_document'
            }
          },
          customer: {
            data: {
              type: 'customer',
              id: '2883'
            },
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3B8V9T/relationships/customer',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3B8V9T/customer'
            }
          },
          invoice_recipient: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3B8V9T/relationships/invoice_recipient',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3B8V9T/invoice_recipient'
            }
          },
          shipment_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3B8V9T/relationships/shipment_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3B8V9T/shipment_address'
            }
          },
          invoice_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3B8V9T/relationships/invoice_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3B8V9T/invoice_address'
            }
          },
          supplier_main_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3B8V9T/relationships/supplier_main_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3B8V9T/supplier_main_address'
            }
          },
          shipments: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3B8V9T/relationships/shipments',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3B8V9T/shipments'
            }
          },
          shipment_lines: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3B8V9T/relationships/shipment_lines',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3B8V9T/shipment_lines'
            }
          }
        },
        links: {
          self:
            'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP3B8V9T'
        }
      },
      {
        type: 'outbound-orders',
        id: 'A5PN7X3RWZ',
        attributes: {
          outbound_order_id: 'A5PN7X3RWZ',
          contact_person: null,
          currency: 'EUR',
          order_total: 0,
          order_reference: null,
          shipping_cost: 0,
          shipment_type: 'pick_up_shipment',
          outbound_order_status: 'CONFIRMED',
          requested_delivery_date: '2021-01-27T00:00:00+00:00',
          order_date: '2021-01-24T21:24:10+00:00',
          confirmation_date: '2021-01-28T14:55:00+00:00',
          supplier_id: 417,
          supplier_name: '417',
          customer_group_partner_id: 460,
          customer_group_partner_name: 'Vonovia',
          confirmation_asset_id: null
        },
        relationships: {
          order_lines: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN7X3RWZ/relationships/order_lines',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN7X3RWZ/order_lines'
            }
          },
          order_document: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN7X3RWZ/relationships/order_document',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN7X3RWZ/order_document'
            }
          },
          customer: {
            data: {
              type: 'customer',
              id: '2311'
            },
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN7X3RWZ/relationships/customer',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN7X3RWZ/customer'
            }
          },
          invoice_recipient: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN7X3RWZ/relationships/invoice_recipient',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN7X3RWZ/invoice_recipient'
            }
          },
          shipment_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN7X3RWZ/relationships/shipment_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN7X3RWZ/shipment_address'
            }
          },
          invoice_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN7X3RWZ/relationships/invoice_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN7X3RWZ/invoice_address'
            }
          },
          supplier_main_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN7X3RWZ/relationships/supplier_main_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN7X3RWZ/supplier_main_address'
            }
          },
          shipments: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN7X3RWZ/relationships/shipments',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN7X3RWZ/shipments'
            }
          },
          shipment_lines: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN7X3RWZ/relationships/shipment_lines',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN7X3RWZ/shipment_lines'
            }
          }
        },
        links: {
          self:
            'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN7X3RWZ'
        }
      },
      {
        type: 'outbound-orders',
        id: 'AKKBHGA1T',
        attributes: {
          outbound_order_id: 'AKKBHGA1T',
          contact_person: null,
          currency: 'EUR',
          order_total: 0,
          order_reference: null,
          shipping_cost: 5,
          shipment_type: 'default_shipment',
          outbound_order_status: 'CANCELED',
          requested_delivery_date: null,
          order_date: '2021-01-24T19:36:09+00:00',
          confirmation_date: '2021-01-28T16:00:00+00:00',
          supplier_id: 100,
          supplier_name: '100',
          customer_group_partner_id: 481,
          customer_group_partner_name: 'Musterkunde GmbH',
          confirmation_asset_id: null
        },
        relationships: {
          order_lines: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/AKKBHGA1T/relationships/order_lines',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/AKKBHGA1T/order_lines'
            }
          },
          order_document: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/AKKBHGA1T/relationships/order_document',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/AKKBHGA1T/order_document'
            }
          },
          customer: {
            data: {
              type: 'customer',
              id: '2878'
            },
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/AKKBHGA1T/relationships/customer',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/AKKBHGA1T/customer'
            }
          },
          invoice_recipient: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/AKKBHGA1T/relationships/invoice_recipient',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/AKKBHGA1T/invoice_recipient'
            }
          },
          shipment_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/AKKBHGA1T/relationships/shipment_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/AKKBHGA1T/shipment_address'
            }
          },
          invoice_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/AKKBHGA1T/relationships/invoice_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/AKKBHGA1T/invoice_address'
            }
          },
          supplier_main_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/AKKBHGA1T/relationships/supplier_main_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/AKKBHGA1T/supplier_main_address'
            }
          },
          shipments: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/AKKBHGA1T/relationships/shipments',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/AKKBHGA1T/shipments'
            }
          },
          shipment_lines: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/AKKBHGA1T/relationships/shipment_lines',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/AKKBHGA1T/shipment_lines'
            }
          }
        },
        links: {
          self:
            'https://order-management.stage.roobeo.com/api/v1/outbound-orders/AKKBHGA1T'
        }
      },
      {
        type: 'outbound-orders',
        id: 'A5PN6B8GZB',
        attributes: {
          outbound_order_id: 'A5PN6B8GZB',
          contact_person: null,
          currency: 'EUR',
          order_total: 0,
          order_reference: null,
          shipping_cost: 5,
          shipment_type: 'default_shipment',
          outbound_order_status: 'RECEIVED',
          requested_delivery_date: null,
          order_date: '2021-01-24T18:42:12+00:00',
          confirmation_date: null,
          supplier_id: 100,
          supplier_name: '100',
          customer_group_partner_id: 481,
          customer_group_partner_name: 'Musterkunde GmbH',
          confirmation_asset_id: null
        },
        relationships: {
          order_lines: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/relationships/order_lines',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/order_lines'
            }
          },
          order_document: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/relationships/order_document',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/order_document'
            }
          },
          customer: {
            data: {
              type: 'customer',
              id: '2875'
            },
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/relationships/customer',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/customer'
            }
          },
          invoice_recipient: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/relationships/invoice_recipient',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/invoice_recipient'
            }
          },
          shipment_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/relationships/shipment_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/shipment_address'
            }
          },
          invoice_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/relationships/invoice_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/invoice_address'
            }
          },
          supplier_main_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/relationships/supplier_main_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/supplier_main_address'
            }
          },
          shipments: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/relationships/shipments',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/shipments'
            }
          },
          shipment_lines: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/relationships/shipment_lines',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/shipment_lines'
            }
          }
        },
        links: {
          self:
            'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB'
        }
      },
      {
        type: 'outbound-orders',
        id: 'A5PN62NS07',
        attributes: {
          outbound_order_id: 'A5PN62NS07',
          contact_person: null,
          currency: 'EUR',
          order_total: 0,
          order_reference: null,
          shipping_cost: 5,
          shipment_type: 'default_shipment',
          outbound_order_status: 'CONFIRMED',
          requested_delivery_date: null,
          order_date: '2021-01-24T18:18:10+00:00',
          confirmation_date: '2021-01-29T14:14:00+00:00',
          supplier_id: 100,
          supplier_name: '100',
          customer_group_partner_id: 481,
          customer_group_partner_name: 'Musterkunde GmbH',
          confirmation_asset_id: null
        },
        relationships: {
          order_lines: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN62NS07/relationships/order_lines',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN62NS07/order_lines'
            }
          },
          order_document: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN62NS07/relationships/order_document',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN62NS07/order_document'
            }
          },
          customer: {
            data: {
              type: 'customer',
              id: '2873'
            },
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN62NS07/relationships/customer',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN62NS07/customer'
            }
          },
          invoice_recipient: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN62NS07/relationships/invoice_recipient',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN62NS07/invoice_recipient'
            }
          },
          shipment_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN62NS07/relationships/shipment_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN62NS07/shipment_address'
            }
          },
          invoice_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN62NS07/relationships/invoice_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN62NS07/invoice_address'
            }
          },
          supplier_main_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN62NS07/relationships/supplier_main_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN62NS07/supplier_main_address'
            }
          },
          shipments: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN62NS07/relationships/shipments',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN62NS07/shipments'
            }
          },
          shipment_lines: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN62NS07/relationships/shipment_lines',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN62NS07/shipment_lines'
            }
          }
        },
        links: {
          self:
            'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN62NS07'
        }
      },
      {
        type: 'outbound-orders',
        id: 'A5PN60VJN2',
        attributes: {
          outbound_order_id: 'A5PN60VJN2',
          contact_person: null,
          currency: 'EUR',
          order_total: 0,
          order_reference: null,
          shipping_cost: 5,
          shipment_type: 'default_shipment',
          outbound_order_status: 'OPEN',
          requested_delivery_date: null,
          order_date: '2021-01-24T18:13:10+00:00',
          confirmation_date: null,
          supplier_id: 100,
          supplier_name: '100',
          customer_group_partner_id: 481,
          customer_group_partner_name: 'Musterkunde GmbH',
          confirmation_asset_id: null
        },
        relationships: {
          order_lines: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN60VJN2/relationships/order_lines',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN60VJN2/order_lines'
            }
          },
          order_document: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN60VJN2/relationships/order_document',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN60VJN2/order_document'
            }
          },
          customer: {
            data: {
              type: 'customer',
              id: '2871'
            },
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN60VJN2/relationships/customer',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN60VJN2/customer'
            }
          },
          invoice_recipient: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN60VJN2/relationships/invoice_recipient',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN60VJN2/invoice_recipient'
            }
          },
          shipment_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN60VJN2/relationships/shipment_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN60VJN2/shipment_address'
            }
          },
          invoice_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN60VJN2/relationships/invoice_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN60VJN2/invoice_address'
            }
          },
          supplier_main_address: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN60VJN2/relationships/supplier_main_address',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN60VJN2/supplier_main_address'
            }
          },
          shipments: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN60VJN2/relationships/shipments',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN60VJN2/shipments'
            }
          },
          shipment_lines: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN60VJN2/relationships/shipment_lines',
              related:
                'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN60VJN2/shipment_lines'
            }
          }
        },
        links: {
          self:
            'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN60VJN2'
        }
      }
    ],
    included: [
      {
        type: 'customer',
        id: '2890',
        attributes: {
          customer_group_id: 460,
          customer_id: 1452,
          first_name: 'Michal',
          last_name: 'Krivan',
          salutation: 'Herr',
          email: 'm.krivan@roobeo.com',
          personnel_number: 'P12345',
          phone_number: null
        }
      },
      {
        type: 'customer',
        id: '2892',
        attributes: {
          customer_group_id: 460,
          customer_id: 1452,
          first_name: 'Michal',
          last_name: 'Krivan',
          salutation: 'Herr',
          email: 'm.krivan@roobeo.com',
          personnel_number: 'P12345',
          phone_number: null
        }
      },
      {
        type: 'customer',
        id: '2888',
        attributes: {
          customer_group_id: 460,
          customer_id: 1452,
          first_name: 'Michal',
          last_name: 'Krivan',
          salutation: 'Herr',
          email: 'm.krivan@roobeo.com',
          personnel_number: 'P12345',
          phone_number: null
        }
      },
      {
        type: 'customer',
        id: '2885',
        attributes: {
          customer_group_id: 460,
          customer_id: 1452,
          first_name: 'Michal',
          last_name: 'Krivan',
          salutation: 'Herr',
          email: 'm.krivan@roobeo.com',
          personnel_number: 'P12345',
          phone_number: null
        }
      },
      {
        type: 'customer',
        id: '2883',
        attributes: {
          customer_group_id: 460,
          customer_id: 1452,
          first_name: 'Michal',
          last_name: 'Krivan',
          salutation: 'Herr',
          email: 'm.krivan@roobeo.com',
          personnel_number: 'P12345',
          phone_number: null
        }
      },
      {
        type: 'customer',
        id: '2311',
        attributes: {
          customer_group_id: 460,
          customer_id: 3088,
          first_name: 'Mathias',
          last_name: 'Ronkin',
          salutation: null,
          email: 'mathias.ronkin@vonovia.de',
          personnel_number: '16001143',
          phone_number: null
        }
      },
      {
        type: 'customer',
        id: '2878',
        attributes: {
          customer_group_id: 481,
          customer_id: 3552,
          first_name: 'Ahmed',
          last_name: 'hassan',
          salutation: null,
          email: 'd0f1a5eb-5b3e-441e-b426-fb54d673ebac@mailslurp.com',
          personnel_number: '1611513217220',
          phone_number: null
        }
      },
      {
        type: 'customer',
        id: '2875',
        attributes: {
          customer_group_id: 481,
          customer_id: 3551,
          first_name: 'Ahmed',
          last_name: 'hassan',
          salutation: null,
          email: '1c4e4b0c-0d66-41b8-bf67-7712fd310191@mailslurp.com',
          personnel_number: '1611509941387',
          phone_number: null
        }
      },
      {
        type: 'customer',
        id: '2873',
        attributes: {
          customer_group_id: 481,
          customer_id: 3546,
          first_name: 'Ahmed',
          last_name: 'hassan',
          salutation: null,
          email: '45f34f8d-36bc-4a05-bfb1-c45e6d116779@mailslurp.com',
          personnel_number: '1611508512439',
          phone_number: null
        }
      },
      {
        type: 'customer',
        id: '2871',
        attributes: {
          customer_group_id: 481,
          customer_id: 3545,
          first_name: 'Ahmed',
          last_name: 'hassan',
          salutation: null,
          email: '4636f188-3410-418b-85e3-7c8806805d61@mailslurp.com',
          personnel_number: '1611507929677',
          phone_number: null
        }
      }
    ]
  };

  return orders;
}

export function generateSinlgeOrder() {
  const orders = {
    data: {
      type: 'outbound-orders',
      id: 'A5PP4BA9FS',
      attributes: {
        outbound_order_id: 'A5PP4BA9FS',
        contact_person: 'contact_test',
        currency: 'EUR',
        order_total: 8,
        order_reference: 'TestRef1',
        shipping_cost: 2.95,
        shipment_type: 'default_shipment',
        outbound_order_status: 'CONFIRMED',
        requested_delivery_date: '2021-02-10T16:40:00+00:00',
        order_date: '2021-01-29T18:30:00+00:00',
        confirmation_date: '2021-02-01T08:20:00+00:00',
        supplier_id: 419,
        supplier_name: '419',
        customer_group_partner_id: 460,
        customer_group_partner_name: 'Vonovia',
        confirmation_asset_id: 1010
      },
      relationships: {
        order_lines: {
          data: [
            {
              type: 'outbound-order-lines',
              id: '3709'
            },
            {
              type: 'outbound-order-lines',
              id: '3710'
            }
          ],
          links: {
            self:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/relationships/order_lines',
            related:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/order_lines'
          }
        },
        order_document: {
          links: {
            self:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/relationships/order_document',
            related:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/order_document'
          }
        },
        customer: {
          data: {
            type: 'customer',
            id: '2890'
          },
          links: {
            self:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/relationships/customer',
            related:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/customer'
          }
        },
        invoice_recipient: {
          data: {
            type: 'customer',
            id: '2891'
          },
          links: {
            self:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/relationships/invoice_recipient',
            related:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/invoice_recipient'
          }
        },
        shipment_address: {
          data: {
            type: 'address',
            id: '2482'
          },
          links: {
            self:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/relationships/shipment_address',
            related:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/shipment_address'
          }
        },
        invoice_address: {
          data: {
            type: 'address',
            id: '2481'
          },
          links: {
            self:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/relationships/invoice_address',
            related:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/invoice_address'
          }
        },
        supplier_main_address: {
          data: {
            type: 'address',
            id: '2480'
          },
          links: {
            self:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/relationships/supplier_main_address',
            related:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/supplier_main_address'
          }
        },
        shipments: {
          data: [],
          links: {
            self:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/relationships/shipments',
            related:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/shipments'
          }
        },
        shipment_lines: {
          data: [],
          links: {
            self:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/relationships/shipment_lines',
            related:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS/shipment_lines'
          }
        }
      },
      links: {
        self:
          'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PP4BA9FS'
      }
    },
    included: [
      {
        type: 'outbound-order-lines',
        id: '3709',
        attributes: {
          customer_item_number: '',
          global_trade_item_number: '4003203464466',
          supplier_item_number: 'S132424',
          stock_keeping_unit: '1234fsa.sku',
          trade_item_name: 'Test Name 1',
          net_price: 0.4,
          sales_unit: 2,
          ordered_quantity: 10,
          confirmed_quantity: 10,
          received_quantity: null,
          shipped_quantity: null,
          canceled_quantity: 0,
          confirmed_price: 0.4,
          confirmed_service_date: '2021-02-10T16:40:00+00:00',
          tax_rate: 0.19,
          order_line_status: 'CONFIRMED',
          packaging_unit: 'Packung',
          packaging_value: 100
        }
      },
      {
        type: 'outbound-order-lines',
        id: '3710',
        attributes: {
          customer_item_number: '',
          global_trade_item_number: '4003203464477',
          supplier_item_number: 'S132425',
          stock_keeping_unit: '1234fjf465.sku',
          trade_item_name: 'Test Name 2',
          net_price: 0.4,
          sales_unit: 2,
          ordered_quantity: 10,
          confirmed_quantity: 10,
          received_quantity: null,
          shipped_quantity: null,
          canceled_quantity: 0,
          confirmed_price: 0.4,
          confirmed_service_date: '2021-02-10T16:40:00+00:00',
          tax_rate: 0.19,
          order_line_status: 'CONFIRMED',
          packaging_unit: 'Packung',
          packaging_value: 100
        }
      },
      {
        type: 'customer',
        id: '2890',
        attributes: {
          customer_group_id: 460,
          customer_id: 1452,
          first_name: 'Michal',
          last_name: 'Krivan',
          salutation: 'Herr',
          email: 'm.krivan@roobeo.com',
          personnel_number: 'P12345',
          phone_number: null
        }
      },
      {
        type: 'customer',
        id: '2891',
        attributes: {
          customer_group_id: 460,
          customer_id: null,
          first_name: 'Rechnungs',
          last_name: 'Empfänger',
          salutation: null,
          email: 'receipt@firstlast.com',
          personnel_number: null,
          phone_number: null
        }
      },
      {
        type: 'address',
        id: '2482',
        attributes: {
          address_type: 'shipment_addresses',
          company: 'Roobeo Versand',
          house_number: null,
          city: 'Berlin',
          state: 'Berlin',
          street: 'Versandstrasse 34',
          postcode: '12345',
          global_location_number: null,
          country: 'de'
        }
      },
      {
        type: 'address',
        id: '2481',
        attributes: {
          address_type: 'invoice_address',
          company: 'Roobeo GmbH',
          house_number: null,
          city: 'Berlin',
          state: 'Berlin',
          street: 'Rechnungsstraße 12',
          postcode: '12345',
          global_location_number: null,
          country: 'de'
        }
      },
      {
        type: 'address',
        id: '2480',
        attributes: {
          address_type: 'main_addresses',
          company: 'ROOBEO',
          house_number: null,
          city: 'Berlin',
          state: 'Berlin',
          street: 'Hauptstraße 23',
          postcode: '12345',
          global_location_number: null,
          country: 'de'
        }
      }
    ]
  };
  return orders;
}

export function generateSinlgeOpenOrder() {
  const orders = {
    data: {
      type: 'outbound-orders',
      id: 'A5PQBBC0QD',
      attributes: {
        outbound_order_id: 'A5PQBBC0QD',
        contact_person: 'contact_test',
        currency: 'EUR',
        order_total: 8,
        order_reference: 'TestRef1',
        shipping_cost: 2.95,
        shipment_type: 'default_shipment',
        outbound_order_status: 'OPEN',
        requested_delivery_date: '2021-02-10T16:40:00+00:00',
        order_date: '2021-02-01T16:30:00+00:00',
        confirmation_date: null,
        supplier_id: 419,
        supplier_name: '419',
        customer_group_partner_id: 460,
        customer_group_partner_name: 'Vonovia',
        confirmation_asset_id: null
      },
      relationships: {
        order_lines: {
          data: [
            {
              type: 'outbound-order-lines',
              id: '3713'
            },
            {
              type: 'outbound-order-lines',
              id: '3714'
            }
          ],
          links: {
            self:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQBBC0QD/relationships/order_lines',
            related:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQBBC0QD/order_lines'
          }
        },
        order_document: {
          links: {
            self:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQBBC0QD/relationships/order_document',
            related:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQBBC0QD/order_document'
          }
        },
        customer: {
          data: {
            type: 'customer',
            id: '2894'
          },
          links: {
            self:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQBBC0QD/relationships/customer',
            related:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQBBC0QD/customer'
          }
        },
        invoice_recipient: {
          data: {
            type: 'customer',
            id: '2895'
          },
          links: {
            self:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQBBC0QD/relationships/invoice_recipient',
            related:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQBBC0QD/invoice_recipient'
          }
        },
        shipment_address: {
          data: {
            type: 'address',
            id: '2488'
          },
          links: {
            self:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQBBC0QD/relationships/shipment_address',
            related:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQBBC0QD/shipment_address'
          }
        },
        invoice_address: {
          data: {
            type: 'address',
            id: '2487'
          },
          links: {
            self:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQBBC0QD/relationships/invoice_address',
            related:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQBBC0QD/invoice_address'
          }
        },
        supplier_main_address: {
          data: {
            type: 'address',
            id: '2486'
          },
          links: {
            self:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQBBC0QD/relationships/supplier_main_address',
            related:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQBBC0QD/supplier_main_address'
          }
        },
        shipments: {
          data: [],
          links: {
            self:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQBBC0QD/relationships/shipments',
            related:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQBBC0QD/shipments'
          }
        },
        shipment_lines: {
          data: [],
          links: {
            self:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQBBC0QD/relationships/shipment_lines',
            related:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQBBC0QD/shipment_lines'
          }
        }
      },
      links: {
        self:
          'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PQBBC0QD'
      }
    },
    included: [
      {
        type: 'outbound-order-lines',
        id: '3713',
        attributes: {
          customer_item_number: '',
          global_trade_item_number: '4003203464466',
          supplier_item_number: 'S132424',
          stock_keeping_unit: '1234fsa.sku',
          trade_item_name: 'Test Name 1',
          net_price: 0.4,
          sales_unit: 2,
          ordered_quantity: 10,
          confirmed_quantity: null,
          received_quantity: null,
          shipped_quantity: null,
          canceled_quantity: null,
          confirmed_price: null,
          confirmed_service_date: null,
          tax_rate: 0.19,
          order_line_status: 'OPEN',
          packaging_unit: 'Stück',
          packaging_value: 100
        }
      },
      {
        type: 'outbound-order-lines',
        id: '3714',
        attributes: {
          customer_item_number: '',
          global_trade_item_number: '4003203464477',
          supplier_item_number: 'S132425',
          stock_keeping_unit: '1234fjf465.sku',
          trade_item_name: 'Test Name 2',
          net_price: 0.4,
          sales_unit: 2,
          ordered_quantity: 10,
          confirmed_quantity: null,
          received_quantity: null,
          shipped_quantity: null,
          canceled_quantity: null,
          confirmed_price: null,
          confirmed_service_date: null,
          tax_rate: 0.19,
          order_line_status: 'OPEN',
          packaging_unit: 'Liter',
          packaging_value: 100
        }
      },
      {
        type: 'customer',
        id: '2894',
        attributes: {
          customer_group_id: 460,
          customer_id: 1452,
          first_name: 'Michal',
          last_name: 'Krivan',
          salutation: 'Herr',
          email: 'm.krivan@roobeo.com',
          personnel_number: 'P12345',
          phone_number: null
        }
      },
      {
        type: 'customer',
        id: '2895',
        attributes: {
          customer_group_id: 460,
          customer_id: null,
          first_name: 'Rechnungs',
          last_name: 'Empfänger',
          salutation: null,
          email: 'receipt@firstlast.com',
          personnel_number: null,
          phone_number: null
        }
      },
      {
        type: 'address',
        id: '2488',
        attributes: {
          address_type: 'shipment_addresses',
          company: 'Roobeo Versand',
          house_number: null,
          city: 'Berlin',
          state: 'Berlin',
          street: 'Versandstrasse 34',
          postcode: '12345',
          global_location_number: null,
          country: 'de'
        }
      },
      {
        type: 'address',
        id: '2487',
        attributes: {
          address_type: 'invoice_address',
          company: 'Roobeo GmbH',
          house_number: null,
          city: 'Berlin',
          state: 'Berlin',
          street: 'Rechnungsstraße 12',
          postcode: '12345',
          global_location_number: null,
          country: 'de'
        }
      },
      {
        type: 'address',
        id: '2486',
        attributes: {
          address_type: 'main_addresses',
          company: 'ROOBEO',
          house_number: null,
          city: 'Berlin',
          state: 'Berlin',
          street: 'Hauptstraße 23',
          postcode: '12345',
          global_location_number: null,
          country: 'de'
        }
      }
    ]
  };
  return orders;
}

export function generateSinlgeReceivedOrder() {
  const orders = {
    data: {
      type: 'outbound-orders',
      id: 'A5PN6B8GZB',
      attributes: {
        outbound_order_id: 'A5PN6B8GZB',
        contact_person: "test person",
        currency: 'EUR',
        order_total: 13.736,
        order_reference: '999999',
        shipping_cost: 5,
        shipment_type: 'default_shipment',
        outbound_order_status: 'RECEIVED',
        requested_delivery_date: '2021-01-24T18:42:12+00:00',
        order_date: '2021-01-24T18:42:12+00:00',
        confirmation_date: null,
        supplier_id: 100,
        supplier_name: 'Baustoff- und Kuriositätenhandel Berlin AG',
        customer_group_partner_id: 481,
        customer_group_partner_name: 'Musterkunde GmbH',
        confirmation_asset_id: null
      },
      relationships: {
        order_lines: {
          data: [
            {
              type: 'outbound-order-lines',
              id: '3689'
            }
          ],
          links: {
            self:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/relationships/order_lines',
            related:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/order_lines'
          }
        },
        order_document: {
          links: {
            self:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/relationships/order_document',
            related:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/order_document'
          }
        },
        customer: {
          data: {
            type: 'customer',
            id: '2876'
          },
          links: {
            self:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/relationships/customer',
            related:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/customer'
          }
        },
        invoice_recipient: {
          data: {
            type: 'customer',
            id: '2877'
          },
          links: {
            self:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/relationships/invoice_recipient',
            related:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/invoice_recipient'
          }
        },
        shipment_address: {
          data: {
            type: 'address',
            id: '2463'
          },
          links: {
            self:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/relationships/shipment_address',
            related:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/shipment_address'
          }
        },
        invoice_address: {
          data: {
            type: 'address',
            id: '2462'
          },
          links: {
            self:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/relationships/invoice_address',
            related:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/invoice_address'
          }
        },
        supplier_main_address: {
          data: null,
          links: {
            self:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/relationships/supplier_main_address',
            related:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/supplier_main_address'
          }
        },
        shipments: {
          data: [
            {
              type: 'shipments',
              id: '455'
            }
          ],
          links: {
            self:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/relationships/shipments',
            related:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/shipments'
          }
        },
        shipment_lines: {
          data: [
            {
              type: 'shipment-order-lines',
              id: '1015'
            }
          ],
          links: {
            self:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/relationships/shipment_lines',
            related:
              'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB/shipment_lines'
          }
        }
      },
      links: {
        self:
          'https://order-management.stage.roobeo.com/api/v1/outbound-orders/A5PN6B8GZB'
      }
    },
    included: [
      {
        type: 'outbound-order-lines',
        id: '3689',
        attributes: {
          customer_item_number: null,
          global_trade_item_number: '4003230003724',
          supplier_item_number: '1754040',
          stock_keeping_unit: '41TWD55JM.2S.DD',
          trade_item_name: 'Bau+Elektrikergips im Eimer 14 0335 VE10kg',
          net_price: 13.736,
          sales_unit: 1,
          ordered_quantity: 1,
          confirmed_quantity: null,
          received_quantity: 1,
          shipped_quantity: 1,
          canceled_quantity: null,
          confirmed_price: null,
          confirmed_service_date: null,
          tax_rate: null,
          order_line_status: 'RECEIVED',
          packaging_unit: 'Stück',
          packaging_value: 1
        }
      },
      {
        type: 'customer',
        id: '2876',
        attributes: {
          customer_group_id: 481,
          customer_id: 3551,
          first_name: 'Ahmed',
          last_name: 'hassan',
          salutation: null,
          email: '1c4e4b0c-0d66-41b8-bf67-7712fd310191@mailslurp.com',
          personnel_number: '1611509941387',
          phone_number: null
        }
      },
      {
        type: 'customer',
        id: '2877',
        attributes: {
          customer_group_id: null,
          customer_id: null,
          first_name: null,
          last_name: null,
          salutation: null,
          email: null,
          personnel_number: null,
          phone_number: null
        }
      },
      {
        type: 'address',
        id: '2463',
        attributes: {
          address_type: 'postal_address',
          company: 'Roobeo Unicorn',
          house_number: null,
          city: 'Berlin',
          state: null,
          street: 'Franz. Str 24',
          postcode: '10119',
          global_location_number: null,
          country: 'DE'
        }
      },
      {
        type: 'address',
        id: '2462',
        attributes: {
          address_type: 'postal_address',
          company: 'Roobeo Unicorn',
          house_number: null,
          city: 'Berlin',
          state: null,
          street: 'Franz. Str 24',
          postcode: '10119',
          global_location_number: null,
          country: 'DE'
        }
      },
      {
        type: 'shipments',
        id: '455',
        attributes: {
          outbound_order_id: 1230,
          receiving_customer: 'Ahmed Hassan',
          delivery_note_id: 'TestAK',
          reception_date: '2021-01-24T19:48:57+00:00',
          shipment_status: 'RECEIVED',
          shipment_date: '2021-01-24T20:48:57+00:00'
        },
        relationships: {
          delivery_notes: {
            links: {
              self:
                'https://order-management.stage.roobeo.com/api/v1/shipments/455/relationships/delivery_notes',
              related:
                'https://order-management.stage.roobeo.com/api/v1/shipments/455/delivery_notes'
            }
          }
        }
      },
      {
        type: 'shipment-order-lines',
        id: '1015',
        attributes: {
          shipment_id: 455,
          outbound_order_line_id: 3689,
          confirmed_quantity: 1,
          received_quantity: 1,
          shipped_quantity: 1,
          order_line_status: 'RECEIVED',
          customer_item_number: 'customerItem',
          global_trade_item_number: '4003230003724',
          net_price: 13.736,
          packaging_unit: 'Stück',
          sales_unit: 1,
          shipment_order_line_id: 1015,
          stock_keeping_unit: '41TWD55JM.2S.DD',
          supplier_item_number: '1754040',
          tax_rate: null,
          currency: 'EUR',
          trade_item_name: 'Bau+Elektrikergips im Eimer 14 0335 VE10kg',
          packaging_value: 1,
          ordered_quantity: 1
        }
      }
    ]
  };
  return orders;
}

const singleOrder = /api\/orders\/\d+$/;
mock.onGet(singleOrder).reply((config) => {
  const order = generateSinlgeOrder();

  return [200, { ...order }];
});

const ordersDb = generateSampleOrders();
const re = /\/api\/orders\//;

mock.onGet(re).reply((config) => {
  const currentPage = parseInt(getQueryVariable('page[number]', config.url));
  const pageSize = parseInt(getQueryVariable('page[size]', config.url));
  const orderBy = getQueryVariable('sort', config.url);
  const order = getQueryVariable('sortDirection', config.url);
  const query = getQueryVariable('q', config.url);
  const numberOfRecords = query ? ordersDb.data.length : 25;

  return [200, { ...ordersDb }];
});
