import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';

interface LogoProps {
  dark?: boolean;
  mini?: boolean;
  width?: 320
}

const Logo: FC<LogoProps> = ({ mini, dark, width }) => {
  let logoFileName = 'logo4';
  logoFileName = mini ? `${logoFileName}-mini` : logoFileName;
  logoFileName = dark ? `${logoFileName}-dark` : logoFileName;
  logoFileName += '.svg';

  const logoSize = mini ? 30 : width;
  const logoHeight = mini ? 25 : 42;

  if (!mini && !dark) {
    // is login page
    return (
      <img
        width="100%"
        data-testid="app-logo"
        alt="Conpur login page logo"
        src={`/static/${logoFileName}`}
      />
    );
  }

  return (
    <img
      width={logoSize}
      data-testid="app-logo"
      height={logoHeight}
      alt="Logo"
      src={`/static/${logoFileName}`}
    />
  );
};

Logo.propTypes = {
  dark: PropTypes.bool,
  mini: PropTypes.bool
};
export default Logo;
