import useScrollReset from 'src/hooks/useScrollReset';
import { FC } from 'react';

const ScrollReset: FC = () => {
  useScrollReset();

  return null;
};

export default ScrollReset;
