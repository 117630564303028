import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/de';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { ConfirmOptions, ConfirmProvider } from 'material-ui-confirm';
import { SnackbarProvider } from 'notistack';
import type { FC } from 'react';
import React from 'react';
import { useIntl } from 'react-intl';
import { Router } from 'react-router-dom';
import CookiesNotification from 'src/components/CookiesNotification';
import GlobalStyles from 'src/components/GlobalStyles';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import ScrollReset from 'src/components/ScrollReset';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import Routes from 'src/routes';
import { createTheme } from 'src/theme';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import ErrorFallback from './components/ErrorFallback';
import { setYupLocale } from './i18n/helpers';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 3000,
      refetchOnWindowFocus: false
    }
  }
});

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App: FC = () => {
  const intl = useIntl();

  // set locale of yup
  setYupLocale(intl);

  const confirmationModalOptions: ConfirmOptions = {
    confirmationButtonProps: {
      variant: 'contained',
      color: 'secondary'

    }
  };
  const theme = createTheme({
    direction: 'ltr',
    responsiveFontSizes: true,
    theme: 'LIGHT'
  });

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <QueryClientProvider client={queryClient}>
              <SnackbarProvider dense maxSnack={3}>
                <ConfirmProvider defaultOptions={confirmationModalOptions}>
                  <Router history={history}>
                    <AuthProvider>
                      <GlobalStyles />
                      <ScrollReset />
                      <GoogleAnalytics />
                      <CookiesNotification />
                      <Routes />
                    </AuthProvider>
                  </Router>
                </ConfirmProvider>
              </SnackbarProvider>
            </QueryClientProvider>
          </ErrorBoundary>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
