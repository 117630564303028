export const APP_VERSION = '1.0.0';

export const THEMES = {
  LIGHT: 'LIGHT'
};

export interface PermissionGroup {
  [key: string]: {
    label: string;
    permissions: string[];
  };
}
