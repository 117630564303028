/* eslint-disable */
import mock from 'src/utils/mock';
import wait from 'src/utils/wait';

const descendingComparator = (a: any, b: any, orderBy: string): number => {
  if (b.attributes[orderBy] < a.attributes[orderBy]) {
    return -1;
  }

  if (b.attributes[orderBy] > a.attributes[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order: 'asc' | 'desc', orderBy: string) => {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
};

const applySort = (users: any[], order: any, orderBy: string): any[] => {
  if (orderBy === 'name') {
    orderBy = 'first_name';
  }
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = users.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    return a[1] - b[1];
  });

  // @ts-ignore
  return stabilizedThis.map((el) => el[0]);
};

const applyPagination = (users: any[], page: number, limit: number): any[] => {
  page -= 1;
  return users.slice(page * limit, page * limit + limit);
};

const applyFilters = (users: any[], query: string): any[] => {
  return users.filter((user) => {
    let matches = true;

    if (query) {
      const properties = [
        'first_name',
        'last_name',
        'email',
        'user_id',
        'job_description'
      ];
      let containsQuery = false;

      properties.forEach((property) => {
        if (
          user.attributes[property]
            .toString()
            .toLowerCase()
            .includes(query.toLowerCase())
        ) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    return matches;
  });
};

function getQueryVariable(variable: string, query: string) {
  const cut = query.slice(query.indexOf('?') + 1);
  const vars = cut.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
}

export function generateUser(qty: number) {
  const users: any[] = [];
  const firstNames: string[] = [
    'Edith',
    'Patterson',
    'Susan',
    'Lizzie',
    'Byrd',
    'Barlow',
    'Elizabeth',
    'Cooke',
    'Crawford',
    'Bonita',
    'Donaldson',
    'Talley',
    'Ramona',
    'Todd',
    'Rios',
    'Velez',
    'Roxie',
    'Berta',
    'Mayra',
    'Mccullough',
    'Diana',
    'Sharpe',
    'Linda'
  ];

  const lastNames: string[] = [
    'Morton',
    'Robles',
    'Powell',
    'Griffin',
    'Cooley',
    'Mason',
    'Hendricks',
    'Pratt',
    'Day',
    'Mcintosh',
    'Hicks',
    'Hawkins',
    'Gonzalez',
    'Gomez',
    'Dickson',
    'Hopper',
    'Head',
    'Alvarado',
    'Wise',
    'Dorsey',
    'Buckner',
    'Whitaker',
    'Flynn'
  ];

  let j = 1000;
  for (let i = 1; i <= qty; i++) {
    const random = Math.floor(Math.random() * 2);
    const random1 = Math.floor(Math.random() * 10);
    const user = {
      type: 'users',
      id: j,
      attributes: {
        user_id: j,
        first_name: firstNames[i % 22],
        last_name: lastNames[i % 22],
        email: `${firstNames[random1]
          .toLowerCase()
          .replace(' ', '.')}@roobeo.com`,
        job_description: 'Lorem ipsum dolor sit amet',
        is_active: random === 1 ? 1 : 0,
        is_deleted: false,
        deactivation_date: '',
        deleted_at: ''
      },
      links: {
        self: '....'
      },
      relationships: {
        user_roles: {
          data: [
            { type: 'user_roles', id: '1200' },
            { type: 'user_roles', id: '1201' }
          ],
          links: {
            self: '',
            related: ''
          }
        }
      }
    };
    users.push(user);
    j++;
  }

  return users;
}

export function generatePermissions() {
  const permissionJson = {
    meta: {
      page: {
        'current-page': 1,
        'per-page': 100,
        from: 1,
        to: 21,
        total: 21,
        'last-page': 1
      }
    },
    links: {
      first:
        'https://auth-service.dev.roobeo.com/api/v1/permissions?page%5Bnumber%5D=1&page%5Bsize%5D=100',
      last:
        'https://auth-service.dev.roobeo.com/api/v1/permissions?page%5Bnumber%5D=1&page%5Bsize%5D=100'
    },
    data: [
      {
        type: 'permissions',
        id: '1',
        attributes: {
          code: 'CRUD_USER_ACCOUNT',
          description: 'Can manage User Accounts'
        },
        relationships: {
          apps: {
            data: [
              {
                type: 'apps',
                id: '1'
              }
            ],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/1/relationships/apps',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/1/apps'
            }
          },
          'permission-groups': {
            data: [
              {
                type: 'permission-groups',
                id: '1'
              },
              {
                type: 'permission-groups',
                id: '5'
              },
              {
                type: 'permission-groups',
                id: '6'
              }
            ],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/1/relationships/permission-groups',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/1/permission-groups'
            }
          }
        },
        links: {
          self: 'https://auth-service.dev.roobeo.com/api/v1/permissions/1'
        }
      },
      {
        type: 'permissions',
        id: '2',
        attributes: {
          code: 'R_USER_MANAGEMENT',
          description: 'Can open User Management'
        },
        relationships: {
          apps: {
            data: [
              {
                type: 'apps',
                id: '1'
              },
              {
                type: 'apps',
                id: '2'
              }
            ],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/2/relationships/apps',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/2/apps'
            }
          },
          'permission-groups': {
            data: [
              {
                type: 'permission-groups',
                id: '1'
              },
              {
                type: 'permission-groups',
                id: '2'
              }
            ],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/2/relationships/permission-groups',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/2/permission-groups'
            }
          }
        },
        links: {
          self: 'https://auth-service.dev.roobeo.com/api/v1/permissions/2'
        }
      },
      {
        type: 'permissions',
        id: '3',
        attributes: {
          code: 'CRUD_USER_ROLE',
          description: 'Can manage User Roles'
        },
        relationships: {
          apps: {
            data: [
              {
                type: 'apps',
                id: '1'
              }
            ],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/3/relationships/apps',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/3/apps'
            }
          },
          'permission-groups': {
            data: [
              {
                type: 'permission-groups',
                id: '1'
              }
            ],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/3/relationships/permission-groups',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/3/permission-groups'
            }
          }
        },
        links: {
          self: 'https://auth-service.dev.roobeo.com/api/v1/permissions/3'
        }
      },
      {
        type: 'permissions',
        id: '4',
        attributes: {
          code: 'CRUD_USER_ROLE_ROOBEO',
          description: 'Can manage User Roles - extended'
        },
        relationships: {
          apps: {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/4/relationships/apps',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/4/apps'
            }
          },
          'permission-groups': {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/4/relationships/permission-groups',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/4/permission-groups'
            }
          }
        },
        links: {
          self: 'https://auth-service.dev.roobeo.com/api/v1/permissions/4'
        }
      },
      {
        type: 'permissions',
        id: '5',
        attributes: {
          code: 'CRUD_PARTNER_ACCOUNT',
          description: 'Can manage Partner Accounts'
        },
        relationships: {
          apps: {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/5/relationships/apps',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/5/apps'
            }
          },
          'permission-groups': {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/5/relationships/permission-groups',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/5/permission-groups'
            }
          }
        },
        links: {
          self: 'https://auth-service.dev.roobeo.com/api/v1/permissions/5'
        }
      },
      {
        type: 'permissions',
        id: '6',
        attributes: {
          code: 'R_ASSORTMENT_ADMINISTRATION',
          description: 'Can open Assortment Administration'
        },
        relationships: {
          apps: {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/6/relationships/apps',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/6/apps'
            }
          },
          'permission-groups': {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/6/relationships/permission-groups',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/6/permission-groups'
            }
          }
        },
        links: {
          self: 'https://auth-service.dev.roobeo.com/api/v1/permissions/6'
        }
      },
      {
        type: 'permissions',
        id: '7',
        attributes: {
          code: 'R_TRADE_ITEM_ROOBEO',
          description: 'Can read trade items as ROOBEO user'
        },
        relationships: {
          apps: {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/7/relationships/apps',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/7/apps'
            }
          },
          'permission-groups': {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/7/relationships/permission-groups',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/7/permission-groups'
            }
          }
        },
        links: {
          self: 'https://auth-service.dev.roobeo.com/api/v1/permissions/7'
        }
      },
      {
        type: 'permissions',
        id: '8',
        attributes: {
          code: 'R_TRADE_ITEM_SUPPLIER',
          description: 'Can read trade items as supplier'
        },
        relationships: {
          apps: {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/8/relationships/apps',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/8/apps'
            }
          },
          'permission-groups': {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/8/relationships/permission-groups',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/8/permission-groups'
            }
          }
        },
        links: {
          self: 'https://auth-service.dev.roobeo.com/api/v1/permissions/8'
        }
      },
      {
        type: 'permissions',
        id: '9',
        attributes: {
          code: 'R_TRADE_ITEM_CUSTOMER',
          description: 'Can read trade items as customer'
        },
        relationships: {
          apps: {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/9/relationships/apps',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/9/apps'
            }
          },
          'permission-groups': {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/9/relationships/permission-groups',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/9/permission-groups'
            }
          }
        },
        links: {
          self: 'https://auth-service.dev.roobeo.com/api/v1/permissions/9'
        }
      },
      {
        type: 'permissions',
        id: '10',
        attributes: {
          code: 'CRUD_CUSTOMER_ITEM_NUMBER',
          description: 'Can manage customer item numbers'
        },
        relationships: {
          apps: {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/10/relationships/apps',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/10/apps'
            }
          },
          'permission-groups': {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/10/relationships/permission-groups',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/10/permission-groups'
            }
          }
        },
        links: {
          self: 'https://auth-service.dev.roobeo.com/api/v1/permissions/10'
        }
      },
      {
        type: 'permissions',
        id: '11',
        attributes: {
          code: 'R_ORDER_MANAGEMENT',
          description: 'Can open Order Management'
        },
        relationships: {
          apps: {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/11/relationships/apps',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/11/apps'
            }
          },
          'permission-groups': {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/11/relationships/permission-groups',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/11/permission-groups'
            }
          }
        },
        links: {
          self: 'https://auth-service.dev.roobeo.com/api/v1/permissions/11'
        }
      },
      {
        type: 'permissions',
        id: '12',
        attributes: {
          code: 'R_CLARIFICATION_MANAGEMENT',
          description: 'Can open Clarification Management'
        },
        relationships: {
          apps: {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/12/relationships/apps',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/12/apps'
            }
          },
          'permission-groups': {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/12/relationships/permission-groups',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/12/permission-groups'
            }
          }
        },
        links: {
          self: 'https://auth-service.dev.roobeo.com/api/v1/permissions/12'
        }
      },
      {
        type: 'permissions',
        id: '13',
        attributes: {
          code: 'R_CLARIFICATION_CASE_ROOBEO',
          description: 'Can read clarification cases as ROOBEO'
        },
        relationships: {
          apps: {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/13/relationships/apps',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/13/apps'
            }
          },
          'permission-groups': {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/13/relationships/permission-groups',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/13/permission-groups'
            }
          }
        },
        links: {
          self: 'https://auth-service.dev.roobeo.com/api/v1/permissions/13'
        }
      },
      {
        type: 'permissions',
        id: '14',
        attributes: {
          code: 'R_CLARIFICATION_CASE_SUPPLIER',
          description: 'Can read clarification cases as Supplier'
        },
        relationships: {
          apps: {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/14/relationships/apps',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/14/apps'
            }
          },
          'permission-groups': {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/14/relationships/permission-groups',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/14/permission-groups'
            }
          }
        },
        links: {
          self: 'https://auth-service.dev.roobeo.com/api/v1/permissions/14'
        }
      },
      {
        type: 'permissions',
        id: '15',
        attributes: {
          code: 'CRUD_DUMMY_CC',
          description: 'Can manage Dummy Clarification Cases'
        },
        relationships: {
          apps: {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/15/relationships/apps',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/15/apps'
            }
          },
          'permission-groups': {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/15/relationships/permission-groups',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/15/permission-groups'
            }
          }
        },
        links: {
          self: 'https://auth-service.dev.roobeo.com/api/v1/permissions/15'
        }
      },
      {
        type: 'permissions',
        id: '16',
        attributes: {
          code: 'CUD_TRADE_ITEM_OFFER_ROOBEO',
          description: 'Can manage trade item offers as ROOBEO'
        },
        relationships: {
          apps: {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/16/relationships/apps',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/16/apps'
            }
          },
          'permission-groups': {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/16/relationships/permission-groups',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/16/permission-groups'
            }
          }
        },
        links: {
          self: 'https://auth-service.dev.roobeo.com/api/v1/permissions/16'
        }
      },
      {
        type: 'permissions',
        id: '17',
        attributes: {
          code: 'CUD_TRADE_ITEM_OFFER_SUPPLIER',
          description: 'Can manage trade item offers as supplier'
        },
        relationships: {
          apps: {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/17/relationships/apps',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/17/apps'
            }
          },
          'permission-groups': {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/17/relationships/permission-groups',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/17/permission-groups'
            }
          }
        },
        links: {
          self: 'https://auth-service.dev.roobeo.com/api/v1/permissions/17'
        }
      },
      {
        type: 'permissions',
        id: '18',
        attributes: {
          code: 'U_TRADE_ITEM_OFFER_CUSTOMER',
          description: 'Can manage trade item offers as customer'
        },
        relationships: {
          apps: {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/18/relationships/apps',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/18/apps'
            }
          },
          'permission-groups': {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/18/relationships/permission-groups',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/18/permission-groups'
            }
          }
        },
        links: {
          self: 'https://auth-service.dev.roobeo.com/api/v1/permissions/18'
        }
      },
      {
        type: 'permissions',
        id: '19',
        attributes: {
          code: 'R_ORDER_OVERVIEW_ROOBEO',
          description: 'Can open Order Overview as ROOBEO'
        },
        relationships: {
          apps: {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/19/relationships/apps',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/19/apps'
            }
          },
          'permission-groups': {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/19/relationships/permission-groups',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/19/permission-groups'
            }
          }
        },
        links: {
          self: 'https://auth-service.dev.roobeo.com/api/v1/permissions/19'
        }
      },
      {
        type: 'permissions',
        id: '20',
        attributes: {
          code: 'C_ORDER_CONFIRMATION',
          description: 'Can post order confirmation'
        },
        relationships: {
          apps: {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/20/relationships/apps',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/20/apps'
            }
          },
          'permission-groups': {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/20/relationships/permission-groups',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/20/permission-groups'
            }
          }
        },
        links: {
          self: 'https://auth-service.dev.roobeo.com/api/v1/permissions/20'
        }
      },
      {
        type: 'permissions',
        id: '21',
        attributes: {
          code: 'U_ORDER_CONFIRMATION',
          description: 'Can correct order confirmation'
        },
        relationships: {
          apps: {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/21/relationships/apps',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/21/apps'
            }
          },
          'permission-groups': {
            data: [],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/21/relationships/permission-groups',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/21/permission-groups'
            }
          }
        },
        links: {
          self: 'https://auth-service.dev.roobeo.com/api/v1/permissions/21'
        }
      }
    ],
    included: [
      {
        type: 'apps',
        id: '1',
        attributes: {
          name: 'App1',
          'created-at': '2020-11-10T15:59:28+00:00',
          'updated-at': '2020-11-10T15:59:28+00:00'
        },
        relationships: {
          permissions: {
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/apps/1/relationships/permissions',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/apps/1/permissions'
            }
          }
        }
      },
      {
        type: 'permission-groups',
        id: '1',
        attributes: {
          name: 'ROOBEO',
          slug: 'ROOBEO'
        },
        relationships: {
          permissions: {
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permission-groups/1/relationships/permissions',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permission-groups/1/permissions'
            }
          }
        }
      },
      {
        type: 'permission-groups',
        id: '5',
        attributes: {
          name: 'Suppliers',
          slug: 'suppliers'
        },
        relationships: {
          permissions: {
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permission-groups/5/relationships/permissions',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permission-groups/5/permissions'
            }
          }
        }
      },
      {
        type: 'permission-groups',
        id: '6',
        attributes: {
          name: 'New PG',
          slug: 'New PG'
        },
        relationships: {
          permissions: {
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permission-groups/6/relationships/permissions',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permission-groups/6/permissions'
            }
          }
        }
      },
      {
        type: 'apps',
        id: '2',
        attributes: {
          name: 'App2',
          'created-at': '2020-11-10T15:59:39+00:00',
          'updated-at': '2020-11-10T15:59:39+00:00'
        },
        relationships: {
          permissions: {
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/apps/2/relationships/permissions',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/apps/2/permissions'
            }
          }
        }
      },
      {
        type: 'permission-groups',
        id: '2',
        attributes: {
          name: 'SUPPLIER',
          slug: 'SUPPLIER'
        },
        relationships: {
          permissions: {
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permission-groups/2/relationships/permissions',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permission-groups/2/permissions'
            }
          }
        }
      }
    ]
  };

  return permissionJson;
}
export function generatePermission() {
  const permissionJson = {
    meta: {
      page: {
        'current-page': 1,
        'per-page': 100,
        from: 1,
        to: 21,
        total: 21,
        'last-page': 1
      }
    },
    links: {
      first:
        'https://auth-service.dev.roobeo.com/api/v1/permissions?page%5Bnumber%5D=1&page%5Bsize%5D=100',
      last:
        'https://auth-service.dev.roobeo.com/api/v1/permissions?page%5Bnumber%5D=1&page%5Bsize%5D=100'
    },
    data: [
      {
        type: 'permissions',
        id: '1',
        attributes: {
          code: 'CRUD_USER_ACCOUNT',
          description: 'Can manage User Accounts'
        },
        relationships: {
          apps: {
            data: [
              {
                type: 'apps',
                id: '1'
              }
            ],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/1/relationships/apps',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/1/apps'
            }
          },
          'permission-groups': {
            data: [
              {
                type: 'permission-groups',
                id: '1'
              },
              {
                type: 'permission-groups',
                id: '5'
              },
              {
                type: 'permission-groups',
                id: '6'
              }
            ],
            links: {
              self:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/1/relationships/permission-groups',
              related:
                'https://auth-service.dev.roobeo.com/api/v1/permissions/1/permission-groups'
            }
          }
        },
        links: {
          self: 'https://auth-service.dev.roobeo.com/api/v1/permissions/1'
        }
      },

    ]
  };

  return permissionJson;
}

const usersDb = generateUser(25);
const re = /\/api\/users\//;

mock.onGet(re).reply((config) => {
  const currentPage = parseInt(getQueryVariable('page[number]', config.url));
  const pageSize = parseInt(getQueryVariable('page[size]', config.url));
  const orderBy = getQueryVariable('sort', config.url);
  const order = getQueryVariable('sortDirection', config.url);
  const query = getQueryVariable('q', config.url);
  const filtered = usersDb.filter(
    (item) => item.attributes.is_deleted !== true
  );
  const appliedQuery = applyFilters(filtered, query);
  const sorted = applySort(appliedQuery, order, orderBy);
  const paginated = applyPagination(sorted, currentPage, pageSize);

  const numberOfRecords = query ? paginated.length : 25;

  const users = {
    data: paginated,
    links: {
      next: '/api/v2/users?page[number]=2&page[size]=5',
      last: '/api/v2/users?page[number]=2&page[size]=5',
      self: '/api/v2/users?page[number]=1&page[size]=5'
    },
    meta: {
      page: {
        total: numberOfRecords,
        number_of_pages: 1
      }
    },
    jsonapi: {
      version: '1.0'
    }
  };

  return [200, { ...users }];
});

mock.onGet('/api/user/permissions').reply((config) => {
  const numberOfRecords = 10;

  const permissions = generatePermissions();

  return [200, { ...permissions }];
});

mock.onGet(/api\/user\/\d+/).reply((config) => {
  const user = generateUser(1);
  const numberOfRecords = 1;

  const users = {
    data: user,
    links: {
      next: '/api/v2/users?page[number]=2&page[size]=5',
      last: '/api/v2/users?page[number]=2&page[size]=5',
      self: '/api/v2/users?page[number]=1&page[size]=5'
    },

    included: [
      {
        type: 'user_roles',
        id: '1200',
        attributes: {
          user_role_id: '1200',
          description: 'Role Description 1',
          is_editable_by_partner: true,
          name: 'Role Name 1'
        },
        links: { self: '' }
      },
      {
        type: 'user_roles',
        id: '1201',
        attributes: {
          user_role_id: '1201',
          description: 'Role Description 2',
          is_editable_by_partner: true,
          name: 'Role Name 2'
        },
        links: { self: '' }
      }
    ],
    meta: {
      number_of_items: numberOfRecords,
      number_of_pages: 1
    },
    jsonapi: {
      version: '1.0'
    }
  };

  return [200, { ...users }];
});

mock.onPost('/api/user/register').reply(async (config) => {
  try {
    await wait(1000);
    return [200, { first_name: 'John', last_name: 'Smith' }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});

mock.onPost('/api/user/update').reply(async (config) => {
  try {
    await wait(1000);
    return [200, { status: 'ok' }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});

mock.onPost('/api/user/delete').reply(async (config) => {
  try {
    await wait(1000);
    return [200, { status: 'ok' }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});

mock.onPost('/api/role/create').reply(async (config) => {
  try {
    await wait(1000);
    return [200, { role_name: 'Role Name' }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});
