import type { FC, ReactElement } from 'react';
import type { Permissions } from 'src/types/permission';

/**
 *
 * @param userPermissions
 * @param actions
 */
export const hasPermission = (
  actions: string[] | null,
  userPermissions: Permissions | null
): boolean => {
  if (!actions) {
    return true;
  }

  let result = false;

  if (!userPermissions) {
    return false;
  }

  actions.forEach((action) => {
    userPermissions.forEach((userPermission) => {
      if (userPermission.code === action) {
        result = true;
      }
    });
  });

  return result;
};

export interface CanProps {
  userPermissions: Permissions | null ;
  perform: string[] | null;
  yes: () => ReactElement | null;
  no: () => ReactElement | null;
}

const Can: FC<CanProps> = ({
  userPermissions, perform, yes, no
}) => (hasPermission(perform, userPermissions) ? yes() : no());

export default Can;
