import jwtDecode from 'jwt-decode';
import type { FC, ReactNode } from 'react';
import React, { createContext, useEffect, useReducer } from 'react';
import SplashScreen from 'src/components/SplashScreen';
import type { Permissions } from 'src/types/permission';
import type { User } from 'src/types/user';
import axios from 'src/utils/axios-instance';
import { useLocation } from 'react-router-dom';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
  permissions: Permissions;
}

export interface AuthContextValue extends AuthState {
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
    permissions: Permissions | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
    permissions: Permissions;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type Action = InitialiseAction | LoginAction | LogoutAction;

const initialAuthState: AuthState = {
  isInitialised: false,
  isAuthenticated: false,
  user: null,
  permissions: []
};

const isValidToken = (accessToken: string): boolean => {
  if (!accessToken) {
    return false;
  }

  const decoded: any = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  // eslint-disable-next-line no-console
  // console.log(`isValidToken: ${decoded.exp} ${currentTime} ${(decoded.exp > currentTime)}`);

  return decoded.exp > currentTime;
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      const {
        isAuthenticated, user, permissions
      } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
        permissions
      };
    }
    case 'LOGIN': {
      const { user, permissions } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
        permissions
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }

    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  login: () => Promise.resolve(),
  logout: () => {}
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const location = useLocation();

  const login = async (email: string, password: string) => {
    const response = await axios.post('/api/v1/login', {
      username: email,
      password,
      grant_type: 'roobeo_password'
    });
    const { token, user, permissions } = response.data;
    setSession(token.access_token);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        permissions
      }
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  useEffect(() => {
    const accessToken = window.localStorage.getItem('accessToken');
    if (!accessToken || !isValidToken(accessToken)) {
      dispatch({
        type: 'LOGOUT'
      });
    }
  }, [location]);

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          try {
            const response = await axios.get<{
              user: User;
              permissions: Permissions;
            }>('/api/v1/users/me');
            const { user, permissions } = response.data;

            dispatch({
              type: 'INITIALISE',
              payload: {
                isAuthenticated: true,
                user,
                permissions
              }
            });
          } catch (e) {
            dispatch({
              type: 'INITIALISE',
              payload: {
                isAuthenticated: false,
                user: null,
                permissions: null
              }
            });
          }
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null,
              permissions: null
            }
          });
        }
      } catch (err) {
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null,
            permissions: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
