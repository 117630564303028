/* eslint-disable */

import mock from 'src/utils/mock';
import wait from 'src/utils/wait';

const descendingComparator = (a: any, b: any, orderBy: string): number => {
  if (b.attributes[orderBy] < a.attributes[orderBy]) {
    return -1;
  }

  if (b.attributes[orderBy] > a.attributes[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order: 'asc' | 'desc', orderBy: string) => {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
};

const applySort = (partners: any[], order: any, orderBy: string): any[] => {
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = partners.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    // @ts-ignore
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    // @ts-ignore
    return a[1] - b[1];
  });

  // @ts-ignore
  return stabilizedThis.map((el) => el[0]);
};

const applyPagination = (
  partners: any[],
  page: number,
  limit: number
): any[] => {
  page -= 1;
  return partners.slice(page * limit, page * limit + limit);
};

const applyFilters = (partners: any[], query: string): any[] => {
  return partners.filter((partner) => {
    let matches = true;

    if (query) {
      const properties = ['name', 'partner_id'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (
          partner.attributes[property]
            .toString()
            .toLowerCase()
            .includes(query.toLowerCase())
        ) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    return matches;
  });
};

function getQueryVariable(variable: string, query: string) {
  const cut = query.slice(query.indexOf('?') + 1);
  const vars = cut.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
}

export function generatePartners(qty: number) {
  const partners: any[] = [];
  const companies: string[] = [
    'SKYBOLD',
    'ACIUM',
    'QUANTALIA',
    'PLEXIA',
    'AUSTECH',
    'VOIPA',
    'SYBIXTEX',
    'OCTOCORE',
    'MACRONAUT',
    'CEPRENE'
  ];

  let j = 1000;
  for (let i = 1; i <= qty; i++) {
    const random = Math.floor(Math.random() * 2);
    const random1 = Math.floor(Math.random() * 10);
    const partner = {
      type: 'partners',
      id: j,
      attributes: {
        partner_id: j,
        partner_name: `${companies[random1]} ${i}`,
        is_active: random === 1 ? 1 : 0,
        is_deleted: false,
        deactivation_date: '',
        deleted_at: ''
      },
      links: {
        self: '....'
      }
    };
    partners.push(partner);
    j++;
  }

  return partners;
}

const partnersDb = generatePartners(10);

export const wrapAsJsonAPI = (data, numberOfRecords) => {
  return {
    data,
    links: {
      next: '/api/v2/partners?page[number]=2&page[size]=5',
      last: '/api/v2/partners?page[number]=2&page[size]=5',
      self: '/api/v2/partners?page[number]=1&page[size]=5'
    },
    meta: {
      page: {
        total: numberOfRecords,
        number_of_pages: 1
      }
    },
    jsonapi: {
      version: '1.0'
    }
  };
};

const partnerRegex = /api\/partner\/\d+$/;
mock.onGet(partnerRegex).reply((config) => {
  const partner = generatePartners(1);
  const output = wrapAsJsonAPI(partner, 1);

  return [200, { ...output }];
});

mock.onGet(new RegExp('/api/partners')).reply((config) => {
  const currentPage = parseInt(getQueryVariable('page[number]', config.url));
  const pageSize = parseInt(getQueryVariable('page[size]', config.url));
  const orderBy = getQueryVariable('sort', config.url);
  const order = getQueryVariable('sortDirection', config.url);
  const query = getQueryVariable('q', config.url);
  const filtered = partnersDb.filter(
    (item) => item.attributes.is_deleted !== true
  );
  const appliedQuery = applyFilters(filtered, query);
  const sorted = applySort(appliedQuery, order, orderBy);
  const paginated = applyPagination(sorted, currentPage, pageSize);

  const numberOfRecords = query ? paginated.length : 10;

  const partners = wrapAsJsonAPI(paginated, numberOfRecords);

  return [200, { ...partners }];
});
mock.onPost('/api/partner/update').reply(async (config) => {
  try {
    await wait(1000);
    return [200, { status: 'ok' }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});

mock.onPost('/api/partner/delete').reply(async (config) => {
  try {
    await wait(1000);
    return [200, { status: 'ok' }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});
