import config from 'src/config';
import axios from 'axios';

const instance = axios.create({
  baseURL: config.apiBaseUrl
});

instance.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';
// instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
instance.defaults.withCredentials = true;

instance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(
    (error.response && error.response.data) || 'Something went wrong'
  )
);

export default instance;
