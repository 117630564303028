/* eslint-disable */

import mock from 'src/utils/mock';
import wait from 'src/utils/wait';

const descendingComparator = (a: any, b: any, orderBy: string): number => {
  if (b.attributes[orderBy] < a.attributes[orderBy]) {
    return -1;
  }

  if (b.attributes[orderBy] > a.attributes[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order: 'asc' | 'desc', orderBy: string) => {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
};

const applySort = (roles: any[], order: any, orderBy: string): any[] => {
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = roles.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    // @ts-ignore
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    // @ts-ignore
    return a[1] - b[1];
  });

  // @ts-ignore
  return stabilizedThis.map((el) => el[0]);
};

const applyPagination = (roles: any[], page: number, limit: number): any[] => {
  page -= 1;
  return roles.slice(page * limit, page * limit + limit);
};

const applyFilters = (roles: any[], query: string): any[] => {
  return roles.filter((role) => {
    let matches = true;

    if (query) {
      const properties = ['name', 'description'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (
          role.attributes[property]
            .toString()
            .toLowerCase()
            .includes(query.toLowerCase())
        ) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    return matches;
  });
};

function getQueryVariable(variable: string, query: string) {
  const cut = query.slice(query.indexOf('?') + 1);
  const vars = cut.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
}

export function generateRole(qty: number) {
  const roles: any[] = [];
  const roleSamples: string[] = [
    'Marketing Department',
    'Admin Department',
    'PO Department',
    'Data Department',
    'Operation Department',
    'Management Department'
  ];

  let j = 1000;
  for (let i = 1; i <= qty; i++) {
    const role = {
      type: 'roles',
      id: j,
      attributes: {
        name: roleSamples[i],
        description: 'Lorem ipsum dolor sit amet. dol',
        is_editable_by_partner: 1
      },
      links: {
        self: '....'
      },
      relationships: {
        permissions: {
          data: [
            { type: 'permissions', id: 'PRM_1001' },
            { type: 'permissions', id: 'PRM_1004' },
            { type: 'permissions', id: 'PRM_1007' }
          ],
          links: {
            self: '',
            related: ''
          }
        }
      }
    };
    roles.push(role);
    j++;
  }

  return roles;
}

const rolesDb = generateRole(5);

mock.onGet(new RegExp('/api/partner/1/roles')).reply((config) => {
  const currentPage = parseInt(getQueryVariable('pageNumber', config.url));
  const pageSize = parseInt(
    getQueryVariable('numberOfItemsPerPage', config.url)
  );
  const orderBy = getQueryVariable('sortedBy', config.url);
  const order = getQueryVariable('sortDirection', config.url);
  const query = getQueryVariable('q', config.url);
  const filtered = rolesDb.filter(
    (item) => item.attributes.is_deleted !== true
  );
  const appliedQuery = applyFilters(filtered, query);
  const sorted = applySort(appliedQuery, order, orderBy);
  const paginated = applyPagination(sorted, currentPage, pageSize);

  const numberOfRecords = query ? paginated.length : 5;

  const roles = {
    data: paginated,
    links: {
      next: '/api/v2/roles?pageNumber=2&numberOfItemsPerPage=5',
      last: '/api/v2/roles?pageNumber=2&numberOfItemsPerPage=5',
      self: '/api/v2/roles?pageNumber=1&numberOfItemsPerPage=5'
    },
    meta: {
      number_of_items: numberOfRecords,
      number_of_pages: 1
    },
    jsonapi: {
      version: '1.0'
    }
  };

  return [200, { ...roles }];
});

mock.onGet(/api\/user-role\/\d+/).reply((config) => {
  const role = generateRole(1);
  const numberOfRecords = 1;

  const roles = {
    data: role,
    links: {
      next: '/api/v2/users?pageNumber=2&numberOfItemsPerPage=5',
      last: '/api/v2/users?pageNumber=2&numberOfItemsPerPage=5',
      self: '/api/v2/users?pageNumber=1&numberOfItemsPerPage=5'
    },

    included: [
      {
        type: 'permissions',
        id: `PRM_${1001}`,
        attributes: {
          permission_id: `PRM_${1001}`,
          description: 'Can Edit User',
          app: 'Assortments',
          permission_groups: ['Roobeo']
        },
        links: { self: '' }
      },
      {
        type: 'permissions',
        id: `PRM_${1003}`,
        attributes: {
          permission_id: `PRM_${1003}`,
          description: 'Can Edit Partner',
          app: 'Assortments',
          permission_groups: ['Roobeo']
        },
        links: { self: '' }
      },
      {
        type: 'permissions',
        id: `PRM_${1007}`,
        attributes: {
          permission_id: `PRM_${1007}`,
          description: 'Can Edit Assortment',
          app: 'Assortments',
          permission_groups: ['Roobeo']
        },
        links: { self: '' }
      }
    ],
    meta: {
      number_of_items: numberOfRecords,
      number_of_pages: 1
    },
    jsonapi: {
      version: '1.0'
    }
  };

  return [200, { ...roles }];
});
mock.onPost('/api/role/update').reply(async (config) => {
  try {
    await wait(1000);
    return [200, { status: 'ok' }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});

mock.onPost('/api/role/delete').reply(async (config) => {
  try {
    await wait(1000);
    return [200, { status: 'ok' }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});
