/* eslint-disable */
import mock from 'src/utils/mock';
import wait from 'src/utils/wait';
const descendingComparator = (a: any, b: any, orderBy: string): number => {
  if (b.attributes[orderBy] < a.attributes[orderBy]) {
    return -1;
  }

  if (b.attributes[orderBy] > a.attributes[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order: 'asc' | 'desc', orderBy: string) => {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
};

const applySort = (tradeitems: any[], order: any, orderBy: string): any[] => {
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = tradeitems.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    // @ts-ignore
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    // @ts-ignore
    return a[1] - b[1];
  });

  // @ts-ignore
  return stabilizedThis.map((el) => el[0]);
};

const applyPagination = (
  tradeitems: any[],
  page: number,
  limit: number
): any[] => {
  page -= 1;
  return tradeitems.slice(page * limit, page * limit + limit);
};

function getQueryVariable(variable: string, query: string) {
  const cut = query.slice(query.indexOf('?') + 1);
  const vars = cut.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
}

export function generateTradeItemOffers(qty: number) {
  const tradeItems: any[] = [];

  const customerGroups: string[] = ['Vonovia', 'B&O', 'Vonovia'];

  const companies: string[] = [
    'Oskar Böttcher GmbH & Co. KG',
    'Oskar Böttcher GmbH & Co. KG',
    'Sonepar Deutschland GmbH'
  ];
  const itemNumbers: number[] = [310152, 310152, 402263];
  const skus: string[] = [
    '3WDO0BGCS.CL.D0',
    '3WDO0BGCS.CL.CS',
    '3WDO0BGCS.DA.CS'
  ];
  const prices: string[] = ['32.29', '27.09', '45.03'];


  let j = 1000;
  for (let i = 0; i < qty; i++) {
    const tradeitem = {
      type: 'tradeitems',
      id: j,
      attributes: {
        trade_item_offer_id: j,
        supplier_trade_item_number: itemNumbers[i],
        stock_keeping_unit: skus[i],
        supplier_name: companies[i],
        customer_group_name: customerGroups[i],
        net_price_per_sales: `${prices[i]} €`,
        sales_unit: '1',
        is_active: Math.ceil(Math.random() * 2) === 1,
        customer_item_number: Math.floor(Math.random() * 1000000),
        minimum_delivery_time: `${Math.ceil(Math.random() * 3)} weeks`,
        maximum_delivery_time: `${Math.ceil(Math.random() * 4)} weeks`,
        delivery_time_unit: 'day',
        minimum_order_quantity: Math.floor(Math.random() * 1000),
        maximum_order_quantity: Math.floor(Math.random() * 2000),
        is_warehouse_item: Math.ceil(Math.random() * 2) === 1,
        import_status: 'Imported',
        created_at: '2020-03-16T07:30:21',
        updated_at: '2020-03-16T07:30:21',
        tax_rate: '13%'
      },
      links: {
        self: '....'
      }
    };
    tradeItems.push(tradeitem);
    j++;
  }

  return tradeItems;
}

const tradeitemsDb = generateTradeItemOffers(3);

export const wrapAsJsonAPI = (data, numberOfRecords) => {
  return {
    data,
    links: {
      next: '/api/v2/tradeitems?pageNumber=2&numberOfItemsPerPage=5',
      last: '/api/v2/tradeitems?pageNumber=2&numberOfItemsPerPage=5',
      self: '/api/v2/tradeitems?pageNumber=1&numberOfItemsPerPage=5'
    },
    meta: {
      number_of_items: numberOfRecords,
      number_of_pages: 1
    },
    jsonapi: {
      version: '1.0'
    }
  };
};


const tradeitemRegex = /api\/trade-item-offer\/\d+$/;

mock.onGet(tradeitemRegex).reply((config) => {
  const tradeItemOffer = {
    id: 1000,
    supplier_item_number: 123456,
    stock_keeping_unit: '3JDJHSL382',
    supplier_name: 'supplie name',
    customer_group_name: '333',
    name: 'Trade Item sample name',
    net_price: 999,
    sales_unit: 10,
    is_active: 0,
    customer_item_number: Math.floor(Math.random() * 1000000),
    minimum_delivery_time: `${Math.ceil(Math.random() * 3)}`,
    maximum_delivery_time: `${Math.ceil(Math.random() * 4)}`,
    delivery_time_unit: 'day',
    minimum_order_quantity: Math.floor(Math.random() * 1000),
    maximum_order_quantity: Math.floor(Math.random() * 2000),
    is_core_assortment: 1,
    import_status: 'Imported',
    created_at: '2020-03-16T07:30:21',
    updated_at: '2020-03-16T07:30:21',
    tax_rate: '13%'
  };

  return [200, { ...tradeItemOffer }];
});


mock.onGet(new RegExp('/api/trade-item-offers')).reply((config) => {
  const orderBy = getQueryVariable('sortedBy', config.url);
  const order = getQueryVariable('sortDirection', config.url);

  const filtered = tradeitemsDb.filter(
    (item) => item.attributes.is_deleted !== true
  );
  const sorted = applySort(filtered, order, orderBy);
  const paginated = applyPagination(sorted, 1, 3);

  const numberOfRecords = 3;

  const tradeitems = wrapAsJsonAPI(paginated, numberOfRecords);

  return [200, { ...tradeitems }];
});


mock.onPost('/api/trade-item-offers/new').reply(async (config) => {
  try {
    await wait(1000);
    return [200, { success: true }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});


mock.onPost('/api/trade-item-offers/edit').reply(async (config) => {
  try {
    await wait(1000);
    return [200, { success: true }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});

mock.onPost('/api/trade-item-offers/delete').reply(async (config) => {
  try {
    await wait(1000);
    return [200, { success: true }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});