import {
  Box, Button, SvgIcon, Typography
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import React, { FC } from 'react';

const useStyles = makeStyles(() => createStyles({
  error: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
  },
  icon: {
    fontSize: '16rem',
    color: '#d4d4d4'
  }
}));

interface Params {
  resetErrorBoundary: any;
}

const refresh = (): void => {
  window.location.reload();
};

const ErrorFallback: FC<Params> = ({ resetErrorBoundary }) => {
  const classes = useStyles();
  return (
    <div role="alert" className={classes.error}>
      <div>
        <SvgIcon className={classes.icon} fontSize="large">
          <SentimentVeryDissatisfiedIcon />
        </SvgIcon>
        <Box mt={2} justifyContent="center" textAlign="center">
          <Typography variant="h4" component="h3" color="textSecondary">
            Oh! Something went wrong...
          </Typography>
          <br />
          <Button
            variant="contained"
            onClick={refresh}
            color="secondary"
          >
            Try again
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default ErrorFallback;
