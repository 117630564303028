/* eslint-disable */
import mock from 'src/utils/mock';
import wait from 'src/utils/wait';

const descendingComparator = (a: any, b: any, orderBy: string): number => {
  if (b.attributes[orderBy] < a.attributes[orderBy]) {
    return -1;
  }

  if (b.attributes[orderBy] > a.attributes[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order: 'asc' | 'desc', orderBy: string) => {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
};

const applySort = (users: any[], order: any, orderBy: string): any[] => {
  if (orderBy === 'name') {
    orderBy = 'first_name';
  }

  const comparator = getComparator(order, orderBy);
  const stabilizedThis = users.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    return a[1] - b[1];
  });

  // @ts-ignore
  return stabilizedThis.map((el) => el[0]);
};

const applyPagination = (users: any[], page: number, limit: number): any[] => {
  page -= 1;
  return users.slice(page * limit, page * limit + limit);
};

const applyFilters = (users: any[], query: string): any[] => {
  return users.filter((user) => {
    let matches = true;

    if (query) {
      const properties = [
        'first_name',
        'last_name',
        'outbound_order_id',
      ];
      let containsQuery = false;

      properties.forEach((property) => {
        if (
          user.attributes[property]
            .toString()
            .toLowerCase()
            .includes(query.toLowerCase())
        ) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    return matches;
  });
};

function getQueryVariable(variable: string, query: string) {
  const cut = query.slice(query.indexOf('?') + 1);
  const vars = cut.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
}

export function generateCases(qty: number) {
  const cases: any[] = [];
  const firstNames: string[] = [
    'Edith',
    'Patterson',
    'Susan',
    'Lizzie',
    'Byrd',
    'Barlow',
    'Elizabeth',
    'Cooke',
    'Crawford',
    'Bonita',
    'Donaldson',
    'Talley',
    'Ramona',
    'Todd',
    'Rios',
    'Velez',
    'Roxie',
    'Berta',
    'Mayra',
    'Mccullough',
    'Diana',
    'Sharpe',
    'Linda'
  ];

  const lastNames: string[] = [
    'Morton',
    'Robles',
    'Powell',
    'Griffin',
    'Cooley',
    'Mason',
    'Hendricks',
    'Pratt',
    'Day',
    'Mcintosh',
    'Hicks',
    'Hawkins',
    'Gonzalez',
    'Gomez',
    'Dickson',
    'Hopper',
    'Head',
    'Alvarado',
    'Wise',
    'Dorsey',
    'Buckner',
    'Whitaker',
    'Flynn'
  ];

  const customerGroups = ['Vonovia', 'B&O', 'Roobeo', 'United'];
  const supplierNames: string[] = ['Vonovia', 'B&O', 'Roobeo'];
  const caseStatuses: string[] = ['open', 'pending', 'closed'];

  let j = 1000;
  for (let i = 1; i <= qty; i++) {
    const ccase = {
      type: 'clarification-case',
      id: j,
      attributes: {
        outbound_order_id: j,
        customer_group_name: customerGroups[i % 4],
        customer_group_id: j,
        first_name: firstNames[i % 22],
        last_name: lastNames[i % 22],
        supplier_name: supplierNames[i % 3],
        supplier_id: j,
        status: caseStatuses[Math.floor(Math.random() * 3)],
        type: 'dummy',
        creation_date: '2020-11-17T07:33:04.000000Z'
      },
      links: {
        self: '....'
      }
    };
    cases.push(ccase);
    j++;
  }

  return cases;
}

const re = /\/api\/clarification-cases\//;

mock.onGet(re).reply((config) => {
  const currentPage = parseInt(getQueryVariable('page[number]', config.url));
  const pageSize = parseInt(getQueryVariable('page[size]', config.url));
  const orderBy = getQueryVariable('sort', config.url);
  const order = getQueryVariable('sortDirection', config.url);
  const query = getQueryVariable('q', config.url);

  let count = 25;
  if(getQueryVariable('filter[customer_group_id]', config.url)) {
    count = 6;
  } else if(getQueryVariable('filter[assigned_user_account_id]', config.url) == '0'){
    count = 8;

  }

  const casesDB = generateCases(count);


  const filtered = casesDB.filter(
    (item) => item.attributes.is_deleted !== true
  );
  const appliedQuery = applyFilters(filtered, query);
  const sorted = applySort(appliedQuery, order, orderBy);
  const paginated = applyPagination(sorted, currentPage, pageSize);

  const numberOfRecords = query ? paginated.length : count;

  const ccases = {
    data: paginated,
    links: {
      next: '/api/v2/cases?page[number]=2&page[size]=5',
      last: '/api/v2/cases?page[number]=2&page[size]=5',
      self: '/api/v2/cases?page[number]=1&page[size]=5'
    },
    meta: {
      page: {
        total: numberOfRecords,
        number_of_pages: 1
      }
    },
    jsonapi: {
      version: '1.0'
    }
  };

  return [200, { ...ccases }];
});

mock.onPost('/api/clarification-cases/1/update').reply(async (config) => {
  try {
    await wait(1000);
    return [200, { success: true }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});